import React from 'react'
import PdfFormPageTemplate from './PdfFormPageTemplate'

const PdfFormChangeLimit = ({
  title = 'MR',
  lastName = '',
  birthName = '',
  firstName = '',
  clientRef = '',
  accountNo = '',
}) => {
  const renderPage1Content = () => {
    return (
      <React.Fragment>
        <section className='pdf-section'>
          <h2 className='pdf-section-title'>Identification / Situation</h2>
          <div className='pdf-section-content'>
            <span className='pdf-highlighted'>Le titulaire,</span>
            <div className='pdf-checkboxes-inline'>
              <div className='pdf-checkbox'>
                <input type='checkbox' defaultChecked={title === 'MME'} />
                <span className='case' />
                <label>Mme</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' defaultChecked={title === 'MLLE'} />
                <span className='case' />
                <label>Mlle</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' defaultChecked={title === 'MR'} />
                <span className='case' />
                <label>M</label>
              </div>
            </div>
            <div className={`pdf-form-line ${lastName !== '' ? 'completed' : ''}`}>
              Nom&nbsp;: <span>{lastName}</span>
            </div>
            <div className={`pdf-form-line ${birthName !== '' ? 'completed' : ''}`}>
              Nom&nbsp;de&nbsp;naissance&nbsp;: <span>{birthName}</span>
            </div>
            <div className={`pdf-form-line ${firstName !== '' ? 'completed' : ''}`}>
              Prénom(s)&nbsp;: <span>{firstName}</span>
            </div>
            <div className={`pdf-form-line ${clientRef !== '' ? 'completed' : ''}`}>
              Référence&nbsp;client&nbsp;: <span>{clientRef}</span>
            </div>
            <div className={`pdf-form-line ${accountNo !== '' ? 'completed' : ''}`}>
              Numéro&nbsp;de&nbsp;compte&nbsp;livret&nbsp;d'épargne&nbsp;Distingo&nbsp;:{' '}
              <span>{accountNo}</span>
            </div>
          </div>
          <div className='pdf-section-content'>
            <br />
            <span className='pdf-highlighted'>Et le co-titulaire éventuel,</span>
            <div className='pdf-checkboxes-inline'>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>Mme</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>Mlle</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>M</label>
              </div>
            </div>
            <div className='pdf-form-line'>
              Nom&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Nom&nbsp;de&nbsp;naissance&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Prénom(s)&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Référence&nbsp;client&nbsp;: <span></span>
            </div>
          </div>
        </section>

        <section className='pdf-section'>
          <div className='pdf-section-content'>
            <div className='pdf-checkbox'>
              <input type='checkbox' />
              <span className='case' />
              <label className='pdf-bold'>
                Demande la suppression du plafond de 50 000 € des retraits effectués à partir de mon
                Espace privé&nbsp;:
              </label>
            </div>
            <div className='indented'>
              <ol className='pdf-highlighted-list-ol'>
                <li>
                  <span>Pour une durée&nbsp;:</span>
                  <div className='pdf-checkbox'>
                    <input type='checkbox' />
                    <span className='case' />
                    <label>
                      Temporaire de 7 jours&nbsp;; à l'issue de cette période, le plafond de 50 000
                      € sera automatiquement remis en place
                    </label>
                  </div>
                  <div className='pdf-checkbox'>
                    <input type='checkbox' />
                    <span className='case' />
                    <label>Permanente</label>
                  </div>
                </li>
                <li>
                  <span>Pour un montant&nbsp;:</span>
                  <div className='pdf-checkbox'>
                    <input type='checkbox' />
                    <span className='case' />
                    <label>
                      Partiel de mes avoirs, soit la somme de&nbsp;:
                      .................................................... €
                    </label>
                  </div>
                  <div className='pdf-checkbox'>
                    <input type='checkbox' />
                    <span className='case' />
                    <label>Total de mes avoirs sur mon livret d'épargne DISTINGO</label>
                  </div>
                </li>
              </ol>
            </div>
            <br />
            <div className='pdf-checkbox'>
              <input type='checkbox' />
              <span className='case' />
              <label className='pdf-bold'>
                Demande le rétablissement du plafond de 50 000 € des retraits effectués à partir de
                mon Espace privé.
              </label>
            </div>
          </div>
        </section>

        <section className='pdf-section sign-section'>
          <div className='pdf-sign-date-place'>
            Fait à ......................................... Le ......./......../........
          </div>
          <p className='pdf-sign-mention'>
            Assurez-vous que les cases soient correctement cochées pour la bonne compréhension de
            votre demande{' '}
          </p>
          <table className='pdf-sign-table double'>
            <tbody>
              <tr>
                <td>Signature du titulaire du compte</td>
                <td>Signature du co-titulaire du compte</td>
              </tr>
            </tbody>
          </table>
        </section>
      </React.Fragment>
    )
  }

  return (
    <div>
      {/* page 1 */}
      <PdfFormPageTemplate
        title='demande de modification du plafond'
        title2="de retrait des fonds sur l'espace privé"
        isFirstPage
        headerCustomText={
          <React.Fragment>
            <span>À renvoyer complétée et signée à&nbsp;:</span>
            <span className='pdf-bold'>DISTINGO - TSA 84321 - 92563 RUEIL MALMAISON CEDEX</span>
            <span>ou via la rubrique dediee de la messagerie de votre Espace privé</span>
          </React.Fragment>
        }
        formRef='MPR042023'>
        {renderPage1Content()}
      </PdfFormPageTemplate>
    </div>
  )
}

export default PdfFormChangeLimit
