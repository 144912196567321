import React from 'react'
import styled, { CSS_VARS } from 'services/styled'

import PdfFormHeader from './PdfFormHeader'
import PdfFormFooter from './PdfFormFooter'

/* A4 dimensions : 210mm x 297mm === 793px x 1122 */
/* mm > px conversion on http://www.endmemo.com/sconvert/millimeterpixel.php */
/* Set footer position always the same :
  footer height = 40px
  header + content height = 1122 - 60 - 40 = 1022
*/

const Container = styled.div`
  position: relative;
  width: 793px;
  height: 1122px;
  padding: 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;

  /* IE 11 */
  @media screen and (-ms-high-contrast: none) {
    font-family: Arial, sans-serif;
  }

  .pageHeight {
    height: 1022px;
  }

  p {
    margin: 0 2px;
  }

  .pdf-bold {
    font-weight: 700;
  }

  .space-top {
    margin-top: 7px !important;
  }

  sup.pdf-sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
    top: -0.5em;
  }

  .pdf-section {
    margin-bottom: 20px;
    color: #000;

    &.sign-section {
      margin: 30px 0 0;
    }
  }

  .pdf-section-title {
    margin: 0 0 7px;
    padding: 5px 10px 5px 20px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    color: ${CSS_VARS.color_white};
    background-color: ${CSS_VARS.color_brand_primary};

    > span {
      text-transform: none;
      font-weight: 400;
      font-size: 11px;
    }
  }

  .pdf-section-content {
    padding: 5px 10px 0 20px;
    font-size: 12px;

    &.largest {
      padding-left: 0;

      .pdf-checkbox {
        margin-right: 15px;
      }
    }
  }

  .pdf-highlighted {
    display: block;
    margin: 0 0 5px;
    color: ${CSS_VARS.color_brand_secondary};
    font-weight: 700;
  }

  .pdf-checkbox {
    display: block;
    margin-right: 20px;

    .case {
      display: inline-block;
      width: 10px;
      height: 10px;
      border: 1px solid #000;
    }
    label {
      position: relative;
      padding-left: 5px;
    }

    input[type='checkbox'] {
      appearence: none;
      display: none;
    }

    input[type='checkbox']:checked + .case {
      background: #000;
    }
  }

  .pdf-checkboxes-inline .pdf-checkbox {
    display: inline-block;
  }

  .pdf-form-line {
    display: flex;
    margin: 0 0 2px;

    > span {
      flex: 1 0 0%;
      margin-left: 10px;
      border-bottom: 1px dotted #666;
    }

    &.completed > span {
      border-bottom: none;
      font-weight: 700;
    }
    &.short > span {
      flex: 0 0 70px;
    }
  }

  .pdf-form-line-mention {
    font-size: 11px;
    font-style: italic;
  }

  .pdf-list {
    margin: 0 0 10px 10px;
    list-style-type: none;

    > li {
      position: relative;
      padding-left: 7px;
      &:before {
        position: absolute;
        left: 0;
        top: 0x;
        display: block;
        content: '-';
      }
    }

    ul {
      margin: 5px 0 10px 20px;
      > li {
        position: relative;
        padding-left: 7px;
        &:before {
          position: absolute;
          left: 0;
          top: 0;
          display: block;
          content: '-';
        }
      }
    }
  }

  .pdf-highlighted-list {
    color: ${CSS_VARS.color_brand_secondary};
    list-style-type: disc;
    list-style-position: inside;

    > li {
      > span {
        font-weight: 700;
      }
      > div {
        margin: 10px 0;
        color: #000;
      }
    }
  }

  .pdf-highlighted-list-ol {
    padding-left: 0;
    color: ${CSS_VARS.color_brand_secondary};
    list-style-position: inside;

    > li {
      > span {
        font-weight: 700;
      }
      > div {
        margin: 10px 0;
        color: #000;
      }
    }
  }

  .indented {
    padding-left: 17px;

    li .pdf-checkbox {
      padding-left: 15px;
    }
  }

  .pdf-sign-date-place {
    margin: 10px 0;
    font-size: 14px;
  }

  .pdf-sign-mention {
    margin: 30px 0 20px;
    font-size: 12px;
    font-weight: 700;
  }

  .rgpd {
    font-size: 12px;
  }

  .pdf-sign-table {
    margin-bottom: 20px;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #000;
    width: 100%;
    height: 150px;
    font-size: 11px;

    tr {
      height: 150px;
    }
    td {
      height: 150px;
      padding: 5px;
      border: 1px solid #000;
      border-bottom: none;
      border-right: none;
      text-align: center;
      vertical-align: top;
      font-size: 11px;
    }

    &.double {
      width: 90%;
    }
    &.single {
      width: 50%;
    }
    &.smallest {
      height: 100px;
      tr,
      td {
        height: 100px;
      }
    }
    &.lowmargin {
      margin-bottom: 10px;
    }
  }

  .pdf-amounts-table {
    display: flex;
    flex-wrap: no-wrap;

    .pdf-amounts-cell {
      flex: 0 0 24%;
      padding: 0 0 0 17px;
      > input {
        padding: 5px;
        border: 1px solid #000;
        text-align: right;
      }

      &.pdf-amounts-right {
        text-align: right;
      }
    }

    .pdf-checkbox-cell {
      flex: 0 0 50%;
      padding: 0 0 0 80px;
    }
  }

  .pdf-bottom-mention {
    margin: 10px 0 0;
    font-size: 12px;

    &.smallest {
      font-size: 11px;
    }
  }
  .section-contrat-montant {
    display: flex;
    gap: 50px;
  }
`

const PdfFormPageTemplate = ({
  children,
  isCopy,
  isFirstPage,
  headerType,
  title,
  title2,
  headerCustomText,
  hasPagination,
  totalPages,
  currentPage,
  formRef,
  hasLogoTri = true,
  hasAddress = true,
  refType = 'left',
}) => {
  return (
    <Container className='pdf-page'>
      <div className='pageHeight'>
        <PdfFormHeader
          title={title}
          title2={title2}
          headerType={headerType}
          isCopy={isCopy}
          isFirstPage={isFirstPage}
          headerCustomText={headerCustomText}
        />
        {children}
      </div>
      <PdfFormFooter
        hasPagination={hasPagination}
        totalPages={totalPages}
        currentPage={currentPage}
        formRef={formRef}
        hasLogoTri={hasLogoTri}
        hasAddress={hasAddress}
        refType={refType}
      />
    </Container>
  )
}

export default PdfFormPageTemplate
