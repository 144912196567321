import React from 'react'

import intl from 'services/intl'
import styled, { CSS_VARS } from 'services/styled'
import utils from 'services/utils'

const ContentTopCSS = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 20px;
  line-height: 30px;

  .pageTitle {
    flex: none;
    width: 100%;
    color: ${CSS_VARS.color_white};
    text-align: left;
    line-height: 70px;
    font-size: 20px;
    font-weight: ${CSS_VARS.weight_semi_bold};
    > div {
      display: inline-block;
      &:last-of-type {
        margin-left: 7px;
      }
    }
  }

  .pageAmounts {
    flex: 1;
    display: flex;
  }

  .totalAssets {
    flex-grow: 0;
    flex-shrink: 0;
    width: 49%;
    color: ${CSS_VARS.color_white};
    text-align: left;
    font-weight: ${CSS_VARS.weight_medium};
    > .title {
      flex: 1;
      font-size: 13px;
      line-height: 1.4;
      text-transform: uppercase;
    }
    > .amount {
      font-weight: ${CSS_VARS.weight_semi_bold};
      line-height: 1.5;
      flex: 1;
      font-size: 24px;
    }

    &:nth-child(2) {
      text-align: right;
    }
  }

  @media only screen and (min-width: ${CSS_VARS.min_desktop_screen}) {
    padding-bottom: 0;
    padding-top: 2px;
    margin-bottom: 0;
    line-height: 1;

    .pageTitle {
      width: 25%;
      padding-right: 30px;
      margin-bottom: 0;
      > div {
        display: block;
        &:first-of-type {
          line-height: 1;
          margin-bottom: 5px;
          font-size: 30px;
          font-weight: ${CSS_VARS.weight_bold};
        }
        &:last-of-type {
          margin-left: 0;
          line-height: 1;
          font-size: 24px;
          font-weight: ${CSS_VARS.weight_semi_bold};
        }
      }
    }

    .pageAmounts {
      padding-left: 3%;
      justify-content: space-between;
    }

    .totalAssets {
      flex: 0 0 30%;
      font-weight: ${CSS_VARS.weight_bold};
      > .title {
        flex: 1;
        margin-bottom: 5px;
        font-size: ${CSS_VARS.size_xs};
        text-transform: uppercase;
        line-height: 1;
      }
      > .amount {
        flex: 1;
        font-size: 34px;
        line-height: 1;
        letter-spacing: 1px;
      }
    }
  }
`

const AccountsContentTop = ({ civility, lastName, myTotalBalance, othersTotalBalance, hasMinorAccounts, hasProxyAccounts }) => {
	const now = new Date()

	return (
    <ContentTopCSS>
      <div className="pageTitle">
        <div>{intl`my_accounts_label_1`}</div>
        <div>
          {civility} {lastName}
        </div>
      </div>
      <div className='pageAmounts'>
        <div className="totalAssets">
          <div className="title">
            {intl`my_accounts_new_header_label_1`}
          </div>
          <div className="amount">{utils.numberFormat(myTotalBalance)}</div>
        </div>
        {
          (hasMinorAccounts || hasProxyAccounts) &&
          <div className="totalAssets">
            <div className="title">{intl`my_accounts_label_2`}</div>
            <div className="amount">{utils.numberFormat(othersTotalBalance)}</div>
          </div>
        }
      </div>
    </ContentTopCSS>
  )
}

export default React.memo(AccountsContentTop)
