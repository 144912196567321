import React, { Component } from 'react'
import intl from 'services/intl'
import { Notification } from 'uic'
import { Content } from './MainTemplate'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.log('error')
    console.log(error)
    console.log('errorInfo')
    console.log(errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <Content>
          <div className="col-sm-12 col-md-9 innerContent">
            <Notification
              type="warning"
              title={intl`error_title`}
              button={{
                title: intl`action_retry`,
                handler: () => {
                  window.location.reload()
                }
              }}
            >
              <p>{intl`generic_error`}</p>
            </Notification>
          </div>
        </Content>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
