import React, { Component, useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import intl from 'services/intl'
import utils from 'services/utils'
import styled, { CSS_VARS } from 'services/styled'
import { ReactComponent as CornerUpIcon } from 'assets/svg/cornerUp.svg'
import { ReactComponent as CornerDownIcon } from 'assets/svg/cornerDown.svg'

import { ROUTES_CONF } from 'services/constants'

const routes = Object.keys(ROUTES_CONF).map((k) => {
  return ROUTES_CONF[k]
})

const MainCSS = styled.div`
  display: none;
  @media (min-width: ${CSS_VARS.min_desktop_screen}) {
    display: block !important;
  }
  > ul {
    padding: 0;
    max-height: calc(100% - 42px);
    background-color: ${CSS_VARS.color_white};
    clear: both;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    > li {
      border-bottom: 1px solid ${CSS_VARS.color_disabled};
      &:last-of-type {
        border-bottom-width: 0;
      }
      > button,
      > a {
        display: flex;
        justify-content: space-between;
        padding: 20px 20px 20px 17px;
        width: 100%;
        color: ${CSS_VARS.color_black};
        text-transform: uppercase;
        font-weight: ${CSS_VARS.weight_semi_bold};
        text-decoration: none;
        /*&:not(button).active,
        &:not(button):hover {*/
        &.active,
        &:hover,
        &:not(a).active-btn {
          color: ${CSS_VARS.color_white};
          background-color: ${CSS_VARS.color_brand_secondary};
          font-weight: ${CSS_VARS.weight_bold};
          /*text-decoration: underline;*/
        }

        > svg {
          width: 16px;
          height: 16px;
          fill: currentColor !important;
        }
        & + div {
          overflow: hidden;
          transition: height 0.5s;
          li {
            border-bottom: 1px solid ${CSS_VARS.color_disabled};
            &:last-of-type {
              border-bottom-width: 0;
            }
            > a {
              display: flex;
              align-items: center;
              height: 60px;
              padding: 0 10px 0 17px;
              text-decoration: none;
              color: ${CSS_VARS.color_grey};
              font-weight: ${CSS_VARS.weight_medium};
              &.active,
              &:hover {
                color: ${CSS_VARS.color_brand_secondary};
                font-weight: ${CSS_VARS.weight_bold};
              }
            }
          }
        }
      }
      &.secondary {
        border-bottom: none;

        > button,
        > a {
          padding: 30px 20px 7px;
          font-size: ${CSS_VARS.size_xxs};
          &.active,
          &:hover {
            color: ${CSS_VARS.color_black};
            background-color: transparent;
            font-weight: ${CSS_VARS.weight_bold};
            text-decoration: underline;
          }
        }
        ~ li.secondary > button,
        ~ li.secondary > a {
          padding: 7px 20px;
        }
        &:last-of-type > button,
        &:last-of-type > a {
          padding: 20px !important;
        }
      }
    }
  }
  > .last-connection {
    margin: 10px 20px;
    font-size: 11px;
    font-weight: ${CSS_VARS.weight_medium};
    color: ${CSS_VARS.color_grey};
    @media (min-width: ${CSS_VARS.min_desktop_screen}) {
      margin: 10px 0;
      font-size: 12px;
    }
  }
`

const Collapsible = ({ title, group, routes, buildLink }) => {
  const history = useHistory()
  const [isOpened, setIsOpened] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const height = routes.length * 60

  const toggle = (event) => {
    // Prevent the closing of the side nav on mobile
    event.nativeEvent.stopImmediatePropagation()
    setIsOpened(!isOpened)
  }

  // Check status when changing page
  const checkStatus = () => {
    const currentRoute = routes.filter((route) => route.path === history.location.pathname)[0]

    if (currentRoute && currentRoute.group === group) {
      setIsOpened(true)
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }

  useEffect(checkStatus, [history.location.pathname])

  return (
    <li className={`collapsible`}>
      <button onClick={toggle} className={`${isActive ? 'active-btn' : ''}`}>
        {intl(title)}
        {isOpened ? <CornerUpIcon /> : <CornerDownIcon />}
      </button>
      <div style={{ height: isOpened ? height : 0 }}>
        <ul>{buildLink(routes, isOpened)}</ul>
      </div>
    </li>
  )
}

class SideNav extends Component {
  render() {
    const {
      hideCoach,
      userSponsorFlag,
      userSponsorEvents,
      isSponsorshipOfferAvailable,
      isSponsorshipEventsAvailable,
      isOfferAvailable,
      hasMinorAccounts,
      hasProxyAccounts,
      isCatFlashAvailable,
      catFlashAuthorizedIds,
      isCatGreenAvailable,
      clientNumber,
      userTanStatus,
      BOSettings,
    } = this.props

    // check pages available for user
    const sponsorshipOfferVisible = isSponsorshipOfferAvailable && userSponsorFlag
    const sponsorshipEventsVisible =
      isSponsorshipEventsAvailable && (userSponsorFlag || userSponsorEvents.length > 0)

    /* const catFlashOfferVisible =
      isCatFlashAvailable && catFlashAuthorizedIds.indexOf(clientNumber) >= 0 */
    const isAuthorizedCatFlash = catFlashAuthorizedIds.indexOf(clientNumber) >= 0

    const tanEnrolVisible = userTanStatus?.status !== 'A'
    const tanManageVisible = userTanStatus?.status === 'A'

    const session = utils.app.get('session')
    const homeLink = routes.filter((route) => route.path === '/')
    let simpleLinks = routes.filter((route) => !route.group && route.path !== '/')

    if (hideCoach) {
      // remove coach route from nav
      simpleLinks = routes.filter(
        (route) => !route.group && route.path !== '/' && route.path !== '/menu_coach'
      )
    } else {
      simpleLinks = routes.filter((route) => !route.group && route.path !== '/')
    }

    const lastConnectionDateHour = session?.lastLoginDate ? dayjs(session.lastLoginDate) : null

    let showCat = true
    const pagesStatus = BOSettings?.pages_setting ?? []
    const page = pagesStatus.filter((page) => page.slug === 'cat')?.[0]
    if (page) {
      showCat = page.enabled
    } else {
      showCat = true
    }

    return (
      <MainCSS className='SideNav'>
        <ul>
          {/* Synthesis in first */}
          {this.buildLink(homeLink, true)}
          {this.buildGroupLink(
            hasMinorAccounts,
            hasProxyAccounts,
            sponsorshipOfferVisible,
            sponsorshipEventsVisible,
            isOfferAvailable,
            isCatFlashAvailable,
            isAuthorizedCatFlash,
            isCatGreenAvailable,
            tanEnrolVisible,
            tanManageVisible,
            showCat
          )}
          {this.buildLink(simpleLinks, true)}
        </ul>
        {lastConnectionDateHour && lastConnectionDateHour.isValid() && (
          <div className='last-connection'>{`${intl(
            'menu_last_connection_date'
          )} ${lastConnectionDateHour.format('DD/MM/YYYY')} ${intl(
            'menu_last_connection_hour'
          )} ${lastConnectionDateHour.format('HH[H]mm')}`}</div>
        )}
      </MainCSS>
    )
  }

  buildLink = (items, hasGroupActive) => {
    return items.map((item) => {
      if (item.isHidden) {
        return null
      }
      const { name, path } = item
      const isCurrent = this.props.location.pathname === path
      const activeClass = isCurrent ? 'active' : ''

      return (
        <li key={name} className={`${item.level && item.level === 'secondary' ? 'secondary' : ''}`}>
          <Link
            aria-current={isCurrent}
            to={{
              pathname: path,
              state: { fromMenu: true },
            }}
            className={activeClass}
            tabIndex={hasGroupActive ? 0 : -1}
            onClick={() => {
              window.dataLayer.push({
                event: 'psa_menu',
                psa_menu: intl(name),
              })
            }}>
            {intl(name)}
          </Link>
        </li>
      )
    })
  }

  buildGroupLink(
    hasMinorAccounts,
    hasProxyAccounts,
    sponsorshipOfferVisible,
    sponsorshipEventsVisible,
    isOfferAvailable,
    isCatFlashAvailable,
    isAuthorizedCatFlash,
    isCatGreenAvailable,
    tanEnrolVisible,
    tanManageVisible,
    showCat
  ) {
    return routes
      .reduce((acc, route) => {
        if (route.group && acc.indexOf(route.group) === -1) {
          if (route.group === 'menu_sponsorship') {
            if (sponsorshipOfferVisible || sponsorshipEventsVisible) {
              acc.push(route.group)
            }
          } else {
            acc.push(route.group)
          }
        }
        return acc
      }, [])
      .map((group) => {
        const groupRoutes = routes.filter((route) => {
          switch (route.name) {
            case 'menu_accounts_minor_accounts':
              return route.group === group && hasMinorAccounts
            case 'menu_accounts_proxy_accounts':
              return route.group === group && hasProxyAccounts
            case 'menu_my_offers_link_4':
              return route.group === group && isOfferAvailable
            case 'menu_offers_CAT':
              return route.group === group && showCat
            case 'menu_offers_cat_flash':
              return route.group === group && isCatFlashAvailable && isAuthorizedCatFlash
            case 'menu_offers_cat_green':
              return route.group === group && isCatGreenAvailable
            case 'menu_sponsorship_offer':
              return route.group === group && sponsorshipOfferVisible
            case 'menu_sponsorship_events':
              return route.group === group && sponsorshipEventsVisible
            case 'menu_tan_enrol':
              return route.group === group && tanEnrolVisible
            case 'menu_tan_manage':
              return route.group === group && tanManageVisible
            default:
              return route.group === group && !route.isHidden
          }
        })

        return (
          <Collapsible
            key={group}
            title={group}
            group={group}
            routes={groupRoutes}
            buildLink={this.buildLink}
          />
        )
      })
  }
}

export default withRouter(SideNav)
