import React, { Component } from 'react'
import intl from 'services/intl'
import utils from 'services/utils'

export default class Form extends Component {
  constructor(props) {
    super(props)

    this.form = React.createRef()
  }

  render() {
    const { children, className = '' } = this.props

    return (
      <form ref={this.form} onSubmit={this.submit} className={className}>
        {children}
      </form>
    )
  }

  submit = (e) => {
    e.preventDefault()
    const { onSubmit } = this.props

    if (this.validation()) {
      onSubmit(this.getFields()).catch((errors) => {
        if (errors) {
          this.postError(errors)
        }
        // return window.Promise.reject()
      })
    }
  }

  reset = () => {
    this.form.current.reset()
  }

  getFields = () => {
    return Array.from(this.form.current.elements).filter((e) => e.getAttribute('name'))
  }

  validation = () => {
    const elements = this.getFields()
    let isValid = true
    let focusIndex = 1000

    elements.forEach((element, i) => {
      if (element.dataset.required === 'true') {
        const parent = utils.closest(element, '.field')
        let error = parent.querySelector('.psa-fieldError')
        let errorId = error.getAttribute('id')

        error.textContent = ''

        if (element.dataset.type === 'email') {
          if (
            !/^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,3})$/.test(
              element.value
            )
          ) {
            isValid = false
            error.textContent = intl('error_format_email')
            element.setAttribute('aria-invalid', 'true')
            element.setAttribute('aria-describedby', errorId)
            if (focusIndex > i) {
              focusIndex = i
            }
          }
        } else if (element.dataset.type === 'phone') {
          if (!/^(0[1-7|9])(\d{8})$/.test(element.value)) {
            isValid = false
            error.textContent = intl('error_format_phone')
            element.setAttribute('aria-invalid', 'true')
            element.setAttribute('aria-describedby', errorId)
            if (focusIndex > i) {
              focusIndex = i
            }
          }
        } else if (element.dataset.type === 'mobile-phone') {
          if (!/^(06|07)(\d{8})$/.test(element.value)) {
            isValid = false
            error.textContent = intl('error_format_mobile_phone')
            element.setAttribute('aria-invalid', 'true')
            element.setAttribute('aria-describedby', errorId)
            if (focusIndex > i) {
              focusIndex = i
            }
          }
        } else if (element.dataset.type === 'home-phone') {
          if (!/^(0[1-5|9])(\d{8})$/.test(element.value)) {
            isValid = false
            error.textContent = intl('error_format_primary_phone')
            element.setAttribute('aria-invalid', 'true')
            element.setAttribute('aria-describedby', errorId)
            if (focusIndex > i) {
              focusIndex = i
            }
          }
        } else if (element.dataset.type === 'integer') {
          if (!/^[0-9]+$/.test(element.value)) {
            isValid = false
            error.textContent = intl('error_format')
            element.setAttribute('aria-invalid', 'true')
            element.setAttribute('aria-describedby', errorId)
            if (focusIndex > i) {
              focusIndex = i
            }
          }
        } else if (element.dataset.type === 'department') {
          if (!/^[0-9]{2}$/.test(element.value)) {
            isValid = false
            error.textContent = intl('error_format')
            element.setAttribute('aria-invalid', 'true')
            element.setAttribute('aria-describedby', errorId)
            if (focusIndex > i) {
              focusIndex = i
            }
          }
        } else if (
          element.dataset.type === 'checkbox' &&
          !document.querySelector(`input[name=${element.name}]:checked`) &&
          !error.textContent
        ) {
          isValid = false
          error.textContent = intl('error_required_field')
          element.setAttribute('aria-invalid', 'true')
          element.setAttribute('aria-describedby', errorId)
          if (focusIndex > i) {
            focusIndex = i
          }
        } else if (
          element.dataset.type === 'radio' &&
          !document.querySelector(`input[name=${element.name}]:checked`) &&
          !error.textContent
        ) {
          isValid = false
          error.textContent = intl('error_required_field')
          element.setAttribute('aria-invalid', 'true')
          element.setAttribute('aria-describedby', errorId)
          if (focusIndex > i) {
            focusIndex = i
          }
        } else if (element.dataset.type === 'password') {
          const fields = document.querySelectorAll(`input[data-type="password"]`)
          // Check the password format
          if (!/^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{10,}$/.test(element.value)) {
            isValid = false
            error.textContent = intl('error_new_password_format')
            element.setAttribute('aria-invalid', 'true')
            element.setAttribute('aria-describedby', errorId)
            if (focusIndex > i) {
              focusIndex = i
            }
            // Check the equality of passwords
          } else if (fields[1].isSameNode(element) && fields[0].value !== element.value) {
            isValid = false
            error.textContent = intl('error_passwords_dont_match')
            element.setAttribute('aria-invalid', 'true')
            element.setAttribute('aria-describedby', errorId)
            if (focusIndex > i) {
              focusIndex = i
            }
          }
        }

        if (!element.value) {
          isValid = false
          error.textContent = intl('error_required_field')
          element.setAttribute('aria-invalid', 'true')
          element.setAttribute('aria-describedby', errorId)
          if (focusIndex > i) {
            focusIndex = i
          }
        }
      }
    })

    // focus on first error
    if (focusIndex !== 1000) {
      elements[focusIndex].focus()
    }

    return isValid
  }

  postError = (errors = []) => {
    this.resetError()
    if (Array.isArray(errors)) {
      errors.forEach((error) => {
        const element = document.getElementById(`${error.name}-error`)

        if (element) {
          element.textContent = intl(error.msg)
        }
      })
    }
  }

  resetError = () => {
    const elements = this.getFields()

    elements.forEach((element) => {
      if (element.dataset.required) {
        const parent = utils.closest(element, '.field')
        const error = parent.querySelector('.psa-fieldError')

        error.textContent = ''
      }
    })
  }
}
