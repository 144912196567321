import React from 'react'
import intl from 'services/intl'
import { connect } from 'services/store'
import styled, { CSS_VARS } from 'services/styled'
import { Content, ContentTop, PageTitle } from 'uic/MainTemplate'
import LoginForm from './LoginForm'

const Fullscreen = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding: 0 0 30px;

  @media (min-width: ${CSS_VARS.min_desktop_screen}) {
    height: auto;
    padding: 30px 0;
  }

  .mockOptions {
    width: 240px;
    margin: 20px auto;
    select {
      width: 100%;
    }
  }
`

const LoginView = ({ chooseMockProfile, selectedMock }) => {
  return (
    <>
      <PageTitle hide />
      <ContentTop hide />
      <Content>
        <div className='col-sm-push-3 col-sm-7'>
          <Fullscreen>
            <LoginForm />
            {/* 
              process.env.REACT_APP_AUTHORIZE_MOCK  === 'true' && 
              <div className='mockOptions'>
                <label htmlFor='chooseMock'>Simulation du service TAN :</label>
                <select
                  id='chooseMock'
                  onChange={chooseMockProfile}
                  value={selectedMock}
                >
                  <option value='none'>Aucune</option>
                  <option value='U'>Client non inscrit</option>
                  <option value='W'>En attente d'activation</option>
                  <option value='A'>Activé</option>
                </select>
              </div>
             */}
          </Fullscreen>
        </div>
      </Content>
    </>
  )
}

const mapStateToProps = ({ view: { chooseMockProfile, selectedMock } }) => {
  return {
    chooseMockProfile,
    selectedMock,
  }
}

export default connect(mapStateToProps)(LoginView)
