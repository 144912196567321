import React, { useEffect, useRef, useState } from 'react'
import styled, { CSS_VARS } from 'services/styled'
import intl from 'services/intl'
import { Field, Form } from 'uic'

const MainCSS = styled.section`
  position: relative;
  text-align: center;

  .psa-blockContent {
    height: 100%;
  }
  .psa-blockLegend {
    max-width: 400px;
    left: -10px;
    text-align: left;
    background-color: #5e5da6;
  }
  .field.floatLabelField {
    max-width: 320px;
    margin: 0 auto;
    label {
      left: 5px;
      padding: 0;
      font-size: 13px;
    }
    .psa-inputText {
      height: 40px;
      padding: 15px 40px 0 5px;
      background-color: transparent;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: ${(props) =>
        props.isCoachSimulator ? '#fe9587' : CSS_VARS.color_brand_primary};
    }
    .symbol {
      position: absolute;
      right: 5px;
      top: 18px;
      font-size: 13px;
    }
  }
  .validateCTA {
    margin: 40px 0 0;
    font-size: 14px;
  }
  .cumul,
  .capital,
  .interests {
    &-label {
      margin-bottom: 5px;
      font-size: 14px;
    }
    &-number {
      font-size: 20px;
      font-weight: 600;
    }
    &:after {
      content: ' ';
      display: block;
      margin: 20px auto 15px;
      width: 50px;
      height: 2px;
      background-color: #1e2336;
    }
  }
  .interests {
    &-label {
      font-weight: 700;
    }
    &:after {
      display: none;
    }
  }
  @media only screen and (min-width: ${CSS_VARS.min_tablet_screen}) {
    flex: ${(props) => (props.withCoachProjects ? '0 0 32%' : '0 0 45%')};

    .psa-blockLegend {
      width: 90%;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 12px;
    }
    .psa-blockContent {
      margin-top: ${(props) => (props.isCoachSimulator ? '0' : '70px')};
      padding: ${(props) => (props.isCoachSimulator ? '60px 20px 0' : '40px 20px 0')};
    }
  }
`

const SimulatorSaving = ({ isCoachSimulator = false, withCoachProjects = false }) => {
  const simIframe = useRef(null)
  const [initialCapital, setInitialCapital] = useState('')
  const [monthlyPayment, setMonthlyPayment] = useState('')
  const [duration, setDuration] = useState('')
  const [simResult, setSimResult] = useState(null)
  useEffect(() => {
    window.addEventListener('message', receiveMessage, false)
    function receiveMessage(event) {
      if (event.origin !== window.origin || !event.data || !('standards' in event.data)) return
      setSimResult(event.data)
    }
  }, [])

  return (
    <MainCSS isCoachSimulator={isCoachSimulator} withCoachProjects={withCoachProjects}>
      <div className='psa-blockContent'>
        <div className='psa-blockLegend'>{intl`coach_overview_title_3`}</div>
        {simResult ? (
          <>
            <div className='cumul'>
              <div className='cumul-label'>{intl`simulator_result_1`}</div>
              <div className='cumul-number'>
                {(Number(initialCapital) + Number(monthlyPayment) * Number(duration)).toFixed(2)} €
              </div>
            </div>
            <div className='capital'>
              <div className='capital-label'>{intl`simulator_result_2`}</div>
              <div className='capital-number'>
                {(
                  simResult.standards +
                  Number(initialCapital) +
                  Number(monthlyPayment) * Number(duration)
                ).toFixed(2)}{' '}
                €
              </div>
            </div>
            <div className='interests'>
              <div className='interests-label'>{intl`simulator_result_3`}</div>
              <div className='interests-number'>{simResult.standards.toFixed(2)} €</div>
            </div>
            <button
              className='psa-button isPrimary validateCTA'
              type='button'
              onClick={() => {
                setSimResult(null)
              }}>{intl`Recommencer`}</button>
          </>
        ) : (
          <Form
            onSubmit={() => {
              simIframe.current.contentWindow.postMessage(
                {
                  initialCapital: Number(initialCapital),
                  monthlyPayment: Number(monthlyPayment),
                  duration: Number(duration),
                },
                window.origin
              )

              return Promise.resolve()
            }}>
            <Field fieldId='label' className='field floatLabelField'>
              <input
                className='psa-inputText'
                name='initialCapital'
                id='initialCapital'
                type='text'
                maxLength='32'
                title={intl`simulator_placeholder_1`}
                data-required
                aria-required='true'
                value={initialCapital}
                onChange={(event) => {
                  event.target.value = event.target.value.trim().replace(/[^0-9]/g, '')
                  setInitialCapital(event.target.value)
                }}
              />
              <label htmlFor='initialCapital'>{intl`simulator_placeholder_1`}*</label>
              <div className='symbol'>€</div>
            </Field>
            <Field fieldId='label' className='field floatLabelField'>
              <input
                className='psa-inputText'
                name='monthlyPayment'
                id='monthlyPayment'
                type='text'
                maxLength='32'
                title={intl`simulator_placeholder_2`}
                data-required
                aria-required='true'
                value={monthlyPayment}
                onChange={(event) => {
                  event.target.value = event.target.value.trim().replace(/[^0-9]/g, '')
                  setMonthlyPayment(event.target.value)
                }}
              />
              <label htmlFor='initialCapital'>{intl`simulator_placeholder_2`}*</label>
              <div className='symbol'>€</div>
            </Field>
            <Field fieldId='label' className='field floatLabelField'>
              <input
                className='psa-inputText'
                name='duration'
                id='duration'
                type='text'
                maxLength='32'
                title={intl`simulator_placeholder_3`}
                data-required
                aria-required='true'
                value={duration}
                onChange={(event) => {
                  event.target.value = event.target.value.trim().replace(/[^0-9]/g, '')
                  setDuration(event.target.value)
                }}
              />
              <label htmlFor='initialCapital'>{intl`simulator_placeholder_3`}*</label>
              <div className='symbol'>{intl`simulator_months`}</div>
            </Field>
            <button className='psa-button isPrimary validateCTA'>{intl`action_validate`}</button>
          </Form>
        )}
      </div>
      <iframe
        sandbox='allow-scripts allow-same-origin'
        ref={simIframe}
        src='/simulateur_distingo.html'
        width={0}
        height={0}
        style={{ visibility: 'hidden' }}
      />
    </MainCSS>
  )
}

export default SimulatorSaving
