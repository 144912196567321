import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import 'dayjs/locale/fr'
import Requester from 'services/api/Requester'
import utils from 'services/utils'
import intl from 'services/intl'
import styled from 'services/styled'
import { SESSION_TIMEOUT, SESSION_TIMEOUT_ALERT } from 'services/constants'
import { Modal } from 'uic'

const MainCSS = styled.div`
  padding: 20px;
  text-align: center;
`

const SessionManager = () => {
  const [showModal, setShowModal] = useState(false)

  const sessionTimeout = () => {
    if (process.env.NODE_ENV === 'development') return

    setInterval(() => {
      let timeout = SESSION_TIMEOUT // 10 minutes

      // Exception for the routes below
      if (
        ['contact', 'myspaceForms', 'openTermAccount', 'openMinorAccount', 'kyc'].indexOf(
          window.location.hash.replace('#/', '')
        ) !== -1
      ) {
        timeout = SESSION_TIMEOUT * 3 // 30 minutes
      }

      let currentToken = ''
      if (utils.app.get('session')) {
        const { idToken } = utils.app.get('session')
        currentToken = idToken || ''
      }

      if (currentToken && Requester.lastRequest) {
        const now = Date.now()

        if (
          parseInt((now - Requester.lastRequest) / 1000) ===
          (timeout - SESSION_TIMEOUT_ALERT) / 1000
        ) {
          setShowModal(true)
        } else if (now - Requester.lastRequest > timeout) {
          setShowModal(false)
          window.location.hash = '/logout'
        }
      }
    }, 1000)
  }

  useEffect(sessionTimeout, [])

  return (
    <>
      {showModal ? (
        <Modal onClose={() => setShowModal(false)}>
          <MainCSS>
            <Helmet>
              <title>{intl`session_expired_title`}</title>
              <meta name="description" content={intl`session_expired_text`} />
            </Helmet>
            <h1>{intl`session_expired_title`}</h1>
            <p>{intl`session_expired_text`}</p>
            <button
              type="submit"
              className="psa-button isPrimary externalButton"
              onClick={() => {
                Requester.lastRequest = Date.now()
                setShowModal(false)
              }}
            >{intl`session_expired_cta`}</button>
          </MainCSS>
        </Modal>
      ) : null}
    </>
  )
}

export default SessionManager
