import React, { Component } from 'react'
import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import styled, { CSS_VARS } from 'services/styled'
import { ReactComponent as CornerLeftIcon } from 'assets/svg/cornerLeft.svg'
import { ReactComponent as CornerRightIcon } from 'assets/svg/cornerRight.svg'

dayjs.extend(isoWeek)

const MainCSS = styled.div`
  display: inline-block;
  padding: 20px;
  width: 350px;
  > nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: ${CSS_VARS.weight_bold};
    > button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      &:focus {
        background-color: ${CSS_VARS.color_input_background};
      }
      > svg {
        width: 22px;
        height: 22px;
        fill: ${CSS_VARS.color_brand_secondary};
      }
    }
    > .selectInputs {
      display: flex;
      > select {
        margin: 0 5px;
        border-radius: 10px;
        text-transform: capitalize;
        &[disabled] {
          -webkit-appearance: none;
          border-width: 0;
        }
      }
    }
  }
  > table {
    width: 100%;
    th,
    td {
      width: 40px;
      height: 40px;
      text-align: center;
      vertical-align: middle;
    }
    th {
      font-size: ${CSS_VARS.size_xs};
      font-weight: ${CSS_VARS.weight_light};
      text-transform: uppercase;
    }
    td {
      padding: 5px;
      button {
        position: relative;
        width: 100%;
        height: 100%;
        font-size: 14px;
        border-radius: 50%;

        &:focus {
          background-color: ${CSS_VARS.color_brand_secondary};
        }
        &.isDate {
          color: ${CSS_VARS.color_product_primary};
        }
        &.isSelected {
          color: ${CSS_VARS.color_white};
          background-color: ${CSS_VARS.color_brand_secondary};
        }
        &.isDisabled {
          opacity: 0.3;
        }
      }
    }
  }
`

class DayAndMonthPicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      now: props.date ? dayjs(props.date) : dayjs().startOf('day'),
      date: props.date ? dayjs(props.date) : null,
      minDate: props.minDate ? dayjs(props.minDate) : null,
      maxDate: props.maxDate ? dayjs(props.maxDate) : null,
      filterDate: props.filterDate != null ? props.filterDate : (date) => true
    }
  }

  render() {
    const weeks = this.getWeeks()
    const { now, date, minDate, maxDate, filterDate } = this.state

    return (
      <MainCSS className="datepicker">
        <nav>
          <button type="button" onClick={this.prev}>
            <CornerLeftIcon />
          </button>
          <div className="selectInputs">
            <select
              value={now.format('M') - 1}
              disabled={!maxDate || !minDate}
              onChange={event => {
                this.navigate(now.set('month', event.target.value))
              }}
            >
              {[...Array(12).keys()].map((val, i) => {
                const month = dayjs()
                  .startOf('year')
                  .add(i, 'months')
                  .format('MMMM')
                return (
                  <option value={i} key={month}>
                    {month}
                  </option>
                )
              })}
            </select>
            <select
              value={now.get('year')}
              disabled={!maxDate || !minDate}
              onChange={event => {
                this.navigate(now.set('year', event.target.value))
              }}
            >
              {maxDate && minDate ? (
                [...Array(maxDate.diff(minDate, 'year')).keys()].map((val, i) => {
                  const year = dayjs().get('year') + i
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  )
                })
              ) : (
                <option value={now.get('year')}>{now.get('year')}</option>
              )}
            </select>
          </div>
          <button type="button" onClick={this.next}>
            <CornerRightIcon />
          </button>
        </nav>
        <table>
          <thead>
            <tr>
              <th>LUN</th>
              <th>MAR</th>
              <th>MER</th>
              <th>JEU</th>
              <th>VEN</th>
              <th>SAM</th>
              <th>DIM</th>
            </tr>
          </thead>
          <tbody>
            {weeks.map((week, r) => {
              return (
                <tr key={r}>
                  {week.map((d, r) => {
                    const isDisabled =
                      !filterDate(d) ||
                      (minDate && d.isBefore(minDate, 'day')) ||
                      (maxDate && d.isAfter(maxDate, 'day')) ||
                      d.month() !== now.month()

                    return (
                      <td key={r}>
                        <button
                          type="button"
                          className={`${isDisabled ? 'isDisabled' : ''}
                           ${dayjs().isSame(d) ? ' isDate' : ''} ${date && date.isSame(d) && d.month() === now.month() ? ' isSelected' : ''
                            }`}
                          onClick={() => {
                            if (!isDisabled) {
                              this.selectDate(d)
                            }
                          }}
                        >
                          {d.date()}
                        </button>
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </MainCSS>
    )
  }

  getWeeks() {
    const startMonth = this.state.now ? this.state.now.startOf('month') : dayjs().startOf('month')
    const currentDay = startMonth.isoWeekday()
    const endMonth = startMonth.endOf('month').isoWeekday()
    const days = []

    const push = function (start, end) {
      for (let i = 0; i < end.diff(start, 'day') + 1; i++) {
        days.push(start.add(i, 'day'))
      }
    }

    // Generates the days for the previous month
    if (currentDay) {
      push(startMonth.startOf('week'), startMonth.subtract('1', 'day'))
    }

    // Generates the days for the current month
    push(startMonth, startMonth.endOf('month'))

    // Generates the days for the next month
    if (endMonth < 7) {
      push(startMonth.endOf('month').add(1, 'day'), startMonth.endOf('month').endOf('week'))
    }

    const week = []

    days.forEach((d, i) => {
      const ti = Math.floor(i / 7)

      if (week[ti]) {
        week[ti].push(d)
      } else {
        week[ti] = [d]
      }
    })

    return week
  }

  prev = () => {
    if (
      this.state.minDate &&
      this.state.now.subtract(1, 'month').isBefore(this.state.minDate, 'month')
    )
      return

    this.setState(state => {
      return {
        now: state.now.subtract(1, 'month')
      }
    })
  }

  next = () => {
    if (this.state.maxDate && this.state.now.add(1, 'month').isAfter(this.state.maxDate, 'month'))
      return

    this.setState(state => {
      return {
        now: state.now.add(1, 'month')
      }
    })
  }

  navigate(now) {
    this.setState({ now })
  }

  selectDate(date) {
    this.setState({ date })
    if (this.props.onChange) {
      this.props.onChange(date)
    }
  }
}

export default DayAndMonthPicker
