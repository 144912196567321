import styled, { createGlobalStyle } from 'styled-components/macro'
import bgHeaderMobile from 'assets/images/backgrounds/header-mobile@2x.jpg'
import bgHeaderDesktop from 'assets/images/backgrounds/header@2x.jpg'

export default styled

export const CSS_VARS = {
  // Colors
  color_brand_primary: '#1e2336', // rgb(30, 35, 54) // hsl(227, 44%, 21%) // bleu nuit
  color_brand_secondary: '#1e2336',
  // color_brand_secondary: '#a5c9d6', // rgb(165, 201, 214) // hsl(196, 23%, 84%) // bleu ciel

  color_product_primary: '#5e5da6', // rgb(94, 93, 166) // hsl(241, 44%, 65%) violet
  color_product_secondary: '#285971', // rgb(40, 89, 113) // hsl(200, 65%, 44%) cyan
  color_product_primary_alt: '#d2b8e6',
  color_product_secondary_alt: '#91c7c0',
  color_white: '#ffffff', // rgb(255, 255, 255) // hsl(0, 0%, 100%) blanc
  color_black: '#000000', // rgb(255, 255, 255) // hsl(0, 0%, 100%) blanc
  color_grey: '#666666', // rgb(255, 255, 255) // hsl(0, 0%, 100%) blanc
  color_background: '#f0f0f0', // rgb(240, 240, 240) // hsl(0, 0%, 94%) gris très clair
  color_input_background: '#ebebeb', // rgb(240, 240, 240) // hsl(0, 0%, 94%) gris très clair
  color_error: '#d53200', // rgb (213, 50, 0) // hsl(14, 100%, 84%) rouge
  color_disabled: '#cccccc', // rgb (204, 204, 204) // hsl(0, 0%, 80%) gris clair

  color_coach_primary: '#fe9587', // rgb(254, 149, 135) // hsl(7, 98%, 76%) // salmon-pink
  color_coach_secondary: '#6de3dc', // rgb(109, 227, 220) // hsl(176, 68%, 66%) // aquamarine blue

  color_flash: '#20c1a8', // rgb(32, 193, 168) // hsl(171, 72%, 44%) // green - blue
  color_green: '#006c54', // rgb(0, 108, 84) // hsl(167, 100%, 21%) // dark green
  color_green_background: '#eef7f6', // rgb(238, 247, 246) // hsl(173, 4%, 97%) // very light green
  color_tan: '#5e5da6', // rgb(94, 93, 166) // hsl(241, 29%, 51%) // purple
  color_tan_text: '#cfb9e3', // rgb(207, 185, 227) // hsl(271, 43%, 81%) // light purple

  // Fonts
  size_xxs: '10px',
  size_xs: '12px',
  size_s: '14px',
  size_m: '17px',
  size_l: '22px',
  size_xl: '28px',
  weight_light: '300',
  weight_regular: '400',
  weight_medium: '500',
  weight_semi_bold: '600',
  weight_bold: '700',

  // Responsives break points
  min_tablet_screen: '768px',
  min_desktop_screen: '992px',
}

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    position: relative;
    background-color: ${CSS_VARS.color_background};
    color: ${CSS_VARS.color_brand_primary};
    background-size: cover;
    user-select: none;
  }

  #root {
    position: relative;
    z-index: 0;
    height: 100%;
    overflow-x: hidden;
    &:after {
      content: '';
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 300px;
      background-repeat: no-repeat;
      background-position: top 0 left 0;
      background-size: 100%;
      background-image: url(${bgHeaderMobile});
    }
    @media only screen and (min-width: 600px) {
      &:after {
        background-image: url(${bgHeaderDesktop});
      }
    }
    @media only screen and (min-width: ${CSS_VARS.min_desktop_screen}) {
      &:after {
        background-image: url(${bgHeaderDesktop});
      }
    }
  }

  * {
    box-sizing: Border-box;
  }

  p {
    color: ${CSS_VARS.color_brand_primary};
  }

  button,
  fieldset,
  ul,
  li {
    margin: 0;
    padding: 0;
    color: ${CSS_VARS.color_brand_primary};
  }

  ul {
    list-style-type: none;
    text-transform: initial;
  }

  button {
    background-color: transparent;
    border-width: 0;
    box-shadow: none;
  }

  input[disabled], input[readonly] {
    color: rgb(84, 84, 84);
    cursor: default;
    &:focus {
      outline: none;
    }
  }

  .innerContent {
    @media only screen and (min-width: ${CSS_VARS.min_desktop_screen}) {
      padding-left: 3%;
    }
  }

  .globalPreventClick {
    position: fixed;
    top: 0;
    z-index: 1;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.7);
  }

  .psa-title-base {
    font-weight: ${CSS_VARS.weight_semi_bold};
    font-size: ${CSS_VARS.size_l};
    color: ${CSS_VARS.color_white};
    text-align: center;

    @media (min-width: ${CSS_VARS.min_desktop_screen}) {
      font-size: ${CSS_VARS.size_xl};
    }
  }

  .psa-inputText {
    padding-top: 6px;
    padding-left: 15px;
    width: 100%;
    height: 50px;
    background-color: ${CSS_VARS.color_input_background};
    border: 0;
    font-size: 16px;
    font-weight: 400;
    color: ${CSS_VARS.color_brand_primary};

    &::-ms-reveal,
    &::-ms-clear { display: none; }

    ::-webkit-input-placeholder {
      color: #999;
    }
    ::-moz-placeholder {
      color: #999;
    }
    :-ms-input-placeholder {
      color: #999;
    }
    :-moz-placeholder {
      color: #999;
    }
  }

  .psa-textarea {
    padding: 20px 15px;
    width: 100%;
    height: 200px;
    border: 0;
    background-color: ${CSS_VARS.color_input_background};
  }

  .psa-select {
    padding: 0 15px;
    width: 100%;
    height: 50px;
    border: 0;
    border-radius: 0;
    color: ${CSS_VARS.color_brand_primary};
    background-color: ${CSS_VARS.color_input_background};
    background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGZpbGw9IiMwMDAwMDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiB2aWV3Qm94PSIwIDAgMzIgMzIiPiA8cGF0aCBkPSJNMC41LDcuNmMtMC43LDAuNy0wLjcsMS45LDAsMi42bDE0LjIsMTQuMmMwLjQsMC4zLDAuOCwwLjUsMS4zLDAuNWMwLjUsMCwwLjktMC4xLDEuMy0wLjVsMTQuMS0xNC4zYzAuNy0wLjcsMC43LTEuOCwwLTIuNSBjLTAuNy0wLjctMS44LTAuNy0yLjUsMEwxNiwyMC42TDMsNy42QzIuMyw2LjksMS4yLDYuOSwwLjUsNy42TDAuNSw3LjZ6Ii8+IDwvc3ZnPg==');
    background-position: right 15px center;
    background-repeat: no-repeat;
    background-size: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .psa-select::-ms-expand {
    display: none;
  }

  .psa-headboard {
    font-weight: ${CSS_VARS.weight_semi_bold};
    font-size: ${CSS_VARS.size_m};
    line-height: 25px;
    color: ${CSS_VARS.color_brand_primary};

    @media (min-width: ${CSS_VARS.min_desktop_screen}) {
      font-size: ${CSS_VARS.size_l};
    }
  }

  .psa-downloadIcon {
    width: 20px;
    height: 20px;
    stroke: #ffffff;
    stroke-width: 2px;
  }

  .psa-fieldError {
    margin-top: 10px;
    color: red;
  }

  .psa-blockLegend {
    position: absolute;
    left: -8px;
    top: 10px;
    margin: 0;
    padding: 10px 10px 10px 13px;
    background-color: ${CSS_VARS.color_brand_primary};
    color: ${CSS_VARS.color_white};
    font-size: ${CSS_VARS.size_xs};
    font-weight: ${CSS_VARS.weight_semi_bold};
    text-transform: uppercase;
    @media (min-width: ${CSS_VARS.min_desktop_screen}) {
      font-size: ${CSS_VARS.size_s};
    }
  }

  .psa-blockLegend.isBook {
    background-color: ${CSS_VARS.color_product_primary};
  }

  .psa-blockLegend.isCTA {
    background-color: ${CSS_VARS.color_product_secondary};
  }

  .psa-blockContent {
    position: relative;
    padding: 70px 26px 20px;
    background-color: ${CSS_VARS.color_white};
    @media (min-width: ${CSS_VARS.min_desktop_screen}) {
      padding: 100px 50px 30px;
    }
  }

  .psa-blockContent:first-of-type {
    margin-top: 20px;
  }

  .psa-blockLegend.isCentered {
    left: 50%;
    transform: translate(-50%, -25px);
  }

  .psa-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: ${CSS_VARS.size_xs};
    font-weight: ${CSS_VARS.weight_bold};
    padding: 10px 10px 10px 16px;
    border: none;
    border-radius: 22px;
    text-transform: uppercase;
    text-decoration: none;
    transition: opacity 0.25s;
    &:after {
      display: inline-block;
      content: " ";
      width: 14px;
      height: 14px;
      margin-left: 4px;
      background: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxMCAxMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTAgMTA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBhdGggZmlsbD0iIzFlMjMzNiIgc3Ryb2tlPSIjMWUyMzM2IiBzdHJva2Utd2lkdGg9IjAuNSIgZD0iTTIuNzksMTBjLTAuMTUsMC0wLjMtMC4wNi0wLjQxLTAuMTdjLTAuMjMtMC4yMy0wLjIzLTAuNTksMC0wLjgyTDYuMzksNUwyLjM4LDAuOTkKCWMtMC4yMy0wLjIzLTAuMjMtMC41OSwwLTAuODJjMC4yMy0wLjIzLDAuNTktMC4yMywwLjgyLDBsNC40Miw0LjQyYzAuMjMsMC4yMywwLjIzLDAuNTksMCwwLjgyTDMuMiw5LjgzQzMuMDksOS45NCwyLjk0LDEwLDIuNzksMTAKCXoiLz4KPC9zdmc+Cg==') no-repeat center;
    }

    &.navigationPrev {
      &:after {
        display: none;
      }
      &:before {
        display: inline-block;
        content: " ";
        width: 14px;
        height: 14px;
        margin-left: 4px;
        background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjQuNiwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhbHF1ZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMTAgMTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEwIDEwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzFFMjMzNjtzdHJva2U6IzFFMjMzNjtzdHJva2Utd2lkdGg6MC41O30KPC9zdHlsZT4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTcuMiwwYzAuMiwwLDAuMywwLjEsMC40LDAuMmMwLjIsMC4yLDAuMiwwLjYsMCwwLjhsLTQsNGw0LDRjMC4yLDAuMiwwLjIsMC42LDAsMC44Yy0wLjIsMC4yLTAuNiwwLjItMC44LDAKCUwyLjQsNS40Yy0wLjItMC4yLTAuMi0wLjYsMC0wLjhsNC40LTQuNEM2LjksMC4xLDcuMSwwLDcuMiwweiIvPgo8L3N2Zz4K") no-repeat center;
      }
    }

    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }

    @media (min-width: ${CSS_VARS.min_desktop_screen}) {
      font-size: 15px;
      &:hover {
        opacity: 0.8;
        text-decoration: none;
      }
    }
  }

  _:-ms-fullscreen, :root .psa-button { height: 50px; }

  .psa-button.isPrimary {
    color: ${CSS_VARS.color_white};
    background-color: ${CSS_VARS.color_brand_primary};
    &:after {
      background: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxMCAxMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTAgMTA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBhdGggZmlsbD0iI2ZmZmZmZiIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2Utd2lkdGg9IjAuNSIgZD0iTTIuNzksMTBjLTAuMTUsMC0wLjMtMC4wNi0wLjQxLTAuMTdjLTAuMjMtMC4yMy0wLjIzLTAuNTksMC0wLjgyTDYuMzksNUwyLjM4LDAuOTkKCWMtMC4yMy0wLjIzLTAuMjMtMC41OSwwLTAuODJjMC4yMy0wLjIzLDAuNTktMC4yMywwLjgyLDBsNC40Miw0LjQyYzAuMjMsMC4yMywwLjIzLDAuNTksMCwwLjgyTDMuMiw5LjgzQzMuMDksOS45NCwyLjk0LDEwLDIuNzksMTAKCXoiLz4KPC9zdmc+Cg==') no-repeat center;
    }
    &.navigationPrev {
      &:after {
        display: none;
      }
      &:before {
        display: inline-block;
        content: " ";
        width: 14px;
        height: 14px;
        margin-left: 4px;
        background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjQuNiwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhbHF1ZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMTAgMTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEwIDEwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6I0ZGRkZGRjtzdHJva2U6I0ZGRkZGRjtzdHJva2Utd2lkdGg6MC41O30KPC9zdHlsZT4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTcuMiwwYzAuMiwwLDAuMywwLjEsMC40LDAuMmMwLjIsMC4yLDAuMiwwLjYsMCwwLjhsLTQsNGw0LDRjMC4yLDAuMiwwLjIsMC42LDAsMC44Yy0wLjIsMC4yLTAuNiwwLjItMC44LDAKCUwyLjQsNS40Yy0wLjItMC4yLTAuMi0wLjYsMC0wLjhsNC40LTQuNEM2LjksMC4xLDcuMSwwLDcuMiwweiIvPgo8L3N2Zz4K") no-repeat center;
      }
    }
  }

  .psa-button.isSecondary {
    color: ${CSS_VARS.color_brand_primary};
    background-color: ${CSS_VARS.color_white};
  }

  .psa-button.isSkull {
    border: 2px solid ${CSS_VARS.color_brand_primary};
    color: ${CSS_VARS.color_brand_primary};
    background-color: transparent;
  }

  .psa-button.isLink {
    color: ${CSS_VARS.color_brand_primary};
    background-color: transparent;
  }

  .Book-dialog-bottom.minorSubscription-nav {
    display: flex;
    justify-content: center;
    margin: 50px 0 0;
    text-align: center;
    > button {
      margin: 0 5px 0 0;
      &:last-of-type {
        margin: 0 0 0 5px;
      }
    }
    @media (min-width: ${CSS_VARS.min_desktop_screen}) {
      > button {
        margin: 0 10px;
        &:last-of-type {
          margin: 0 10px;
        }
      }
    }
  }

  .minorAccount-headerHeight {
    height: 0;
    @media (min-width: ${CSS_VARS.min_desktop_screen}) {
      height: 140px;
    }
  }
  
  form {
    input {
      &[disabled],
      &[readonly] {
        color: #999;
        cursor: not-allowed;
        background-color: #f4f4f4;
      }
    }
    .radioLabel {
      flex: 1;
      margin: 0 10px 0 0;
      > div {
        margin-right: 5px;
        vertical-align: middle;
        > input + span {
          border-radius: 0 !important;
          background-color: ${CSS_VARS.color_white};
        }
        > input:checked + span {
          background-color: ${CSS_VARS.color_brand_secondary};
        }
      }
    }
  }

  form.kycForm {
    > .psa-blockContent {
      padding-top: 20px;
      .indications {
        margin-bottom: 30px;
        p {
          margin: 0;
          padding: 20px 0;
          font-style: italic;
        }
      }
    }
    .field.floatLabelField {
      margin-bottom: 25px;
    }
  }

  .form-notice.minorAccountFormNotice {
    display: block;
    margin-top: 40px;
    font-size: 12px;
  }
  @media (min-width: ${CSS_VARS.min_tablet_screen}) {
    form {
      > .psa-blockContent {
        padding-bottom: 30px;
      }
      .field {
        .psa-inputText.psa-inputText-short {
          max-width: 93px;
        }
      }
      input {
        &[disabled],
        &[readonly] {
          color: #999;
          cursor: not-allowed;
          background-color: #e7e7e7;
        }
      }
    }
    form.minorAccountForm {
      .fieldsWrapper {
        margin: 5px auto;
        max-width: 320px;
      }
    }
    form.kycForm {
      padding-bottom: 50px;
      > .psa-blockContent {
        padding-top: 50px;
        .indications {
          margin-bottom: 40px;
          p {
            padding: 0 20px 20px;
          }
        }
      }
      .fieldsWrapper.colWrapper {
        margin: 5px auto;
        display: flex;
        justify-content: space-between;
        .fieldsCol {
          flex: 0 0 48%;
        }
      }
      .checkboxLabel {
        flex: 1;
        margin: 0 10px 0 0;
        > div {
          margin-right: 5px;
          vertical-align: middle;
          > input {
            width: 26px;
            height: 26px;
          }
          > input + span {
            width: 26px;
            height: 26px;
            border-radius: 0 !important;
            background-color: ${CSS_VARS.color_white};
          }
          > input:checked + span {
            background-color: ${CSS_VARS.color_brand_primary};
          }
        }
      }
      .field.floatLabelField {
        margin-bottom: 20px;
      }
    }
    .form-notice.minorAccountFormNotice {
      display: block;
      margin: 60px auto 0;
      max-width: 320px;
    }
    .form-notice.kycFormNotice {
      display: block;
      margin: 60px auto 0;
    }
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    form.minorAccountForm .field.floatLabelField .psa-inputText.psa-inputText-short {
        width: calc(calc(100% - 40px) / 3);
        &:nth-of-type(2) {
          margin: 0 20px;
          & + label {
            left: 123px;
          }
        }
        &:nth-of-type(3) + label {
          left: 237px;
        }
      }
  }

  .field.floatLabelField {
    position: relative;
    margin-bottom: 15px;
    label {
      position: absolute;
      z-index: 2;
      top: -10px;
      left: 10px;
      padding: 0 5px;
      color: ${CSS_VARS.color_brand_primary};
      font-weight: 400;
      transform: translate(0, 26px) scale(1);
      transform-origin: left center;
      transition: all 0.3s ease-in;
      pointer-events: none;
    }
    input:focus + label,
    textarea:focus + label,
    input.filled + label,
    textarea.filled + label,
    select + label {
      color: ${CSS_VARS.color_brand_primary};
      font-weight: normal;
      background-color: transparent;
      transform: translate(0, 10px) scale(0.75);
    }
    input[readonly] + label,
    textarea[readonly] + label {
      color: ${CSS_VARS.color_brand_primary};
      font-weight: normal;
      background-color: transparent;
      transform: translate(0, 10px) scale(0.75);
    }
    .psa-inputText.psa-inputText-short {
      width: calc(calc(100% - 40px) / 3);
      &:nth-of-type(2) {
        margin: 0 20px;
        & + label {
          left: calc(calc(100% - 40px) / 3 + 30px);
        }
      }
      &:nth-of-type(3) + label {
        left: calc(calc(100% - 40px) / 3 * 2 + 50px);
      }
    }

    @media (min-width: ${CSS_VARS.min_tablet_screen}) {
      .field.floatLabelField {
        label {
          color: ${CSS_VARS.color_brand_primary};
        }
        input:focus + label,
        textarea:focus + label,
        input.filled + label,
        textarea.filled + label,
        select + label {
          color: ${CSS_VARS.color_brand_primary};
        }
        input[readonly] + label, 
        textarea[readonly] + label {
          color: ${CSS_VARS.color_brand_primary};
        }
      }
    }
  }
  
  /* Tooltip classes */
  .psa-tooltip {
    max-width: 300px;
    text-align: center;
  }

  .psa-tooltip-trigger {
    display: inline-block;
    &:after {
      content: 'i';
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      border: 1px solid #ffffff;
      border-radius: 100%;
      color: #ffffff;
      font-size: 12px;
      font-weight: 600;
    }
    &-light:after {
      border: 1px solid #666666;
      color: #666666;
    }
  }

  /* IE11 hack */
  _:-ms-fullscreen, :root .homeContent .slider-item-content {
    overflow-y: hidden;
  }

  _:-ms-fullscreen, :root .homeContent .slider-item-image {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    height: auto !important;
    width: 100%;
    transform: translateY(-50%);
  }

  _:-ms-fullscreen, :root .ComDocs-docItem {
    align-items: center;
  }
  _:-ms-fullscreen, :root .ComDocs-downloadButton {
    display: flex;
    align-items: center;
  }
  _:-ms-fullscreen, :root .ComDocs-downloadIcon {
    height: 20px !important;
  }

  _:-ms-fullscreen, :root .coach-psa-blockContent .slider-item-image-wrapper {
    overflow: hidden;
    flex: 0 0 80px;
  }

  _:-ms-fullscreen, :root .coach-psa-blockContent .slider-item-image {
    width: auto !important;
    height: auto !important;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%) scale(0.4);
  }

  _:-ms-fullscreen, :root .coach-psa-blockContent .slider-item-description {
    display: block !important;
    max-height: 55px;
  }
`
