import Requester from 'services/api/Requester'
import { showNotification } from 'services/store/actions'
import intl from 'services/intl'

class BORequester extends Requester {
  constructor() {
    super()

    this.baseUrl = process.env.REACT_APP_BO_URL
    this.apiKey = process.env.REACT_APP_BO_API_KEY
  }

  format([wsError, wsResponse]) {
    let response = {
      data: null,
      error: false,
      isCanceled: false,
    }

    if (wsError) {
      response.error = true

      if (wsError.response) {
        const {
          response: { status, data },
        } = wsError

        if (status === 401) {
          window.location.hash = '/logout'
        } else if (status === 400 && data.code) {
          showNotification({
            type: 'error',
            title: intl`error_title`,
            text: intl(`error_${data.code}`),
          })
        } else {
          response.data = data
        }
      }
    } else {
      response.data = wsResponse.data
    }

    return response
  }

  async getMessage(page) {
    const response = await this.fetch({
      method: 'GET',
      route: `/api/1.0/message?page=${page}&platform=web&apiKey=${this.apiKey}`,
      headers: {
        Accept: 'application/json',
      },
    })

    return this.format(response)
  }

  async getProductMessage(product) {
    //livret_epargne, livret_mineur, cat
    const response = await this.fetch({
      method: 'GET',
      route: `/api/1.0/product/${product}?apiKey=${this.apiKey}`,
      headers: {
        Accept: 'application/json',
      },
    })

    return this.format(response)
  }

  async getSettings() {
    // get login_setting, pages status &nominal_rate
    const response = await this.fetch({
      method: 'GET',
      route: `/api/1.0/setting?apiKey=${this.apiKey}`,
      headers: {
        Accept: 'application/json',
      },
    })

    return this.format(response)
  }

  async getSynthesisSliderItems() {
    const response = await this.fetch({
      method: 'GET',
      route: `/api/1.0/carousel?apiKey=${this.apiKey}`,
      headers: {
        Accept: 'application/json',
      },
    })

    return this.format(response)
  }

  async copartisServiceTracker(data) {
    const response = await this.fetch({
      method: 'POST',
      route: `/api/1.0/logging?apiKey=${this.apiKey}`,
      data,
      headers: {
        Accept: 'application/json',
      },
    })

    return this.format(response)
  }

  async transferDisabledDates() {
    const response = await this.fetch({
      method: 'GET',
      route: `/api/1.0/transfer_disabled_dates?apiKey=${this.apiKey}`,
      headers: {
        Accept: 'application/json',
      },
    })

    return this.format(response)
  }

  async getCategories() {
    const response = await this.fetch({
      method: 'GET',
      route: `/api/1.0/project/categories?apiKey=${this.apiKey}`,
      headers: {
        Accept: 'application/json',
      },
    })

    return this.format(response)
  }

  async getBudget(clientId, savingAmount) {
    const response = await this.fetch({
      method: 'GET',
      route: `/api/1.0/client/${clientId}/budget?amount=${savingAmount}&apiKey=${this.apiKey}`,
      headers: {
        Accept: 'application/json',
      },
    })

    return this.format(response)
  }

  /* coach webservices */
  async createProject(data) {
    const response = await this.fetch({
      method: 'POST',
      route: `/api/1.0/project?apiKey=${this.apiKey}`,
      data,
      headers: {
        Accept: 'application/json',
      },
    })

    return this.format(response)
  }

  async closeProject(projectId) {
    const response = await this.fetch({
      method: 'GET',
      route: `/api/1.0/project/${projectId}/close?apiKey=${this.apiKey}`,
      headers: {
        Accept: 'application/json',
      },
    })

    return this.format(response)
  }

  async updateProject({ id, ...data }) {
    const response = await this.fetch({
      method: 'PUT',
      route: `/api/1.0/project/${id}?apiKey=${this.apiKey}`,
      data,
      headers: {
        Accept: 'application/json',
      },
    })

    return this.format(response)
  }

  async initializeMatelas(clientId, data) {
    const response = await this.fetch({
      method: 'POST',
      route: `/api/1.0/client/${clientId}/saving?apiKey=${this.apiKey}`,
      data,
      headers: {
        Accept: 'application/json',
      },
    })

    return this.format(response)
  }

  async getMatelas(clientId) {
    const response = await this.fetch({
      method: 'GET',
      route: `/api/1.0/client/${clientId}/saving?apiKey=${this.apiKey}`,
      headers: {
        Accept: 'application/json',
      },
    })

    return this.format(response)
  }

  async updateProjectAlloc(clientId, data) {
    const response = await this.fetch({
      method: 'POST',
      route: `/api/1.0/client/${clientId}/project_percentages?apiKey=${this.apiKey}`,
      data,
      headers: {
        Accept: 'application/json',
      },
    })

    return this.format(response)
  }

  async getUserSavingProfile(clientId) {
    const response = await this.fetch({
      method: 'GET',
      route: `/api/1.0/client/${clientId}/profile?apiKey=${this.apiKey}`,
      headers: {
        Accept: 'application/json',
      },
    })

    return this.format(response)
  }

  async updateSavingProfile(clientId, data) {
    const response = await this.fetch({
      method: 'POST',
      route: `/api/1.0/client/${clientId}/profile?apiKey=${this.apiKey}`,
      data,
      headers: {
        Accept: 'application/json',
      },
    })

    return this.format(response)
  }

  /* fidelity offer content */
  async getFidelityOffer() {
    const response = await this.fetch({
      method: 'GET',
      route: `/api/1.0/offer?apiKey=${this.apiKey}`,
      headers: {
        Accept: 'application/json',
      },
    })

    return this.format(response)
  }

  /* sponsorship webservices */
  async getSponsorshipOfferContent() {
    const response = await this.fetch({
      method: 'GET',
      route: `/api/1.0/sponsorship_offer?apiKey=${this.apiKey}`,
      headers: {
        Accept: 'application/json',
      },
    })

    return this.format(response)
  }

  async getCatFlashSettings() {
    const response = await this.fetch({
      method: 'GET',
      route: `/api/1.0/product/cat_flash?apiKey=${this.apiKey}`,
      headers: {
        Accept: 'application/json',
      },
    })

    return this.format(response)
  }

  async getCatGreenSettings() {
    const response = await this.fetch({
      method: 'GET',
      route: `/api/1.0/product/cat_green?apiKey=${this.apiKey}`,
      headers: {
        Accept: 'application/json',
      },
    })

    return this.format(response)
  }

  // send client push token
  async checkRecaptcha(token) {
    const response = await this.fetch({
      method: 'GET',
      route: `/api/1.0/recaptcha/verify/${token}?apiKey=${this.apiKey}`,
      headers: {
        Accept: 'application/json',
      },
    })

    return this.format(response)
  }
}

export default BORequester
