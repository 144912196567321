import React, { useState } from 'react'

import styled, { CSS_VARS } from 'services/styled'
import intl from 'services/intl'
import utils from 'services/utils'
import { Field } from 'uic'

const Decimal = require('decimal.js-light')

const MainCSS = styled.section`
  position: relative;
  text-align: center;
  margin-bottom: 50px;

  .psa-blockContent {
    height: 100%;
  }
  .psa-blockLegend {
    max-width: 400px;
    left: -10px;
    background-color: #285971;
    text-align: left;
  }

  .field.floatLabelField {
    max-width: 320px;
    margin: 0 auto;

    label {
      left: 5px;
      padding: 0;
      font-size: 13px;
    }

    .psa-inputText {
      height: 40px;
      padding: 15px 40px 0 5px;
      background-color: transparent;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: ${(props) =>
        props.isCoachSimulator ? '#fe9587' : CSS_VARS.color_brand_primary};
    }

    .symbol {
      position: absolute;
      right: 5px;
      top: 18px;
      font-size: 13px;
    }
  }
  .results {
    .label {
      margin-top: 30px;
      font-size: 17px;
      font-weight: 700;
    }
    .notice {
      font-size: 13px;
    }
    .number {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 36px;
      font-weight: 700;
      > span {
        font-size: 32px;
      }
    }
    .duration button {
      margin: 10px;
      opacity: 0.4;
      font-weight: 700;
      text-transform: uppercase;
      &.active {
        opacity: 1;
        text-decoration: underline;
      }
    }
  }
  .validateCTA {
    margin: 30px 0 0;
  }

  @media only screen and (min-width: ${CSS_VARS.min_tablet_screen}) {
    flex: ${(props) => (props.withCoachProjects ? '0 0 32%' : '0 0 45%')};
    margin-bottom: 0;

    .psa-blockLegend {
      width: 90%;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 12px;
    }
    .psa-blockContent {
      margin-top: ${(props) => (props.isCoachSimulator ? '0' : '10px')};
      padding: ${(props) => (props.isCoachSimulator ? '60px 20px 0' : '40px 20px 0')};
    }
  }
`

const SimulatorCAT = ({
  isCoachSimulator = false,
  withCoachProjects = false,
  simCATError,
  simCATData,
}) => {
  const [selectedDuration, setSelectedDuration] = useState(1)
  const [simData, setSimData] = useState(null)
  const isNewCatEnabled = utils.app.get('isNewCatEnabled')

  const simulatorCATInterests = (capital) => {
    const taux1an = simCATData.taux_dinterets_cat_1_an
    const taux2ans = simCATData.taux_dinterets_cat_2_ans
    const taux3ans = simCATData.taux_dinterets_cat_3_ans

    if (!capital || !taux1an || !taux2ans || !taux3ans) {
      return
    }
    const nCapital = new Decimal(capital)
    const nTaux1an = new Decimal(taux1an).dividedBy(100)
    const nTaux2ans = new Decimal(taux2ans).dividedBy(100) // toNumber : 0.013
    const nTaux3ans = new Decimal(taux3ans).dividedBy(100) // toNumber : 0.014
    setSimData({
      oneYearInterest: nCapital
        .times(nTaux1an.plus(1))
        .minus(nCapital)
        .toDecimalPlaces(2, 6)
        .toNumber(),
      twoYearsInterest: nCapital
        .times(nTaux2ans.plus(1).times(nTaux2ans.plus(1)))
        .minus(nCapital)
        .toDecimalPlaces(2, 6)
        .toNumber(),
      threeYearsInterest: nCapital
        .times(nTaux3ans.plus(1).times(nTaux3ans.plus(1).times(nTaux3ans.plus(1))))
        .minus(nCapital)
        .toDecimalPlaces(2, 6)
        .toNumber(),
    })
  }

  const newSimulatorCATInterests = (capital) => {
    const taux1an = simCATData.taux_dinterets_cat_1_an

    if (!capital || !taux1an) {
      return
    }
    const nCapital = new Decimal(capital)
    const nTaux1an = new Decimal(taux1an).dividedBy(100)
    setSimData({
      oneYearInterest: nCapital
        .times(nTaux1an.plus(1))
        .minus(nCapital)
        .toDecimalPlaces(2, 6)
        .toNumber(),
    })
  }

  const onInputChange = (event) => {
    const val = event.target.value.trim().replace(/[^0-9]/g, '')
    if (event.target.value) {
      if (isNewCatEnabled) {
        newSimulatorCATInterests(val)
      } else {
        simulatorCATInterests(val)
      }
    } else {
      setSimData(null)
    }
  }

  const SimulatorResults = () => {
    return (
      <div className='results'>
        <div className='label'>{intl`simulator_result_4`}</div>
        <div className='notice'>{intl`simulator_result_5`}</div>
        <div className='number'>
          {selectedDuration === 1 && simData.oneYearInterest}
          {selectedDuration === 2 && simData.twoYearsInterest}
          {selectedDuration === 3 && simData.threeYearsInterest}
          <span>€</span>
        </div>
        <div className='duration'>
          <button
            onClick={() => setSelectedDuration(1)}
            className={selectedDuration === 1 ? 'active' : ''}>
            1 {intl`simulator_result_7`}
          </button>
          <button
            onClick={() => setSelectedDuration(2)}
            className={selectedDuration === 2 ? 'active' : ''}>
            2 {intl`simulator_result_8`}
          </button>
          <button
            onClick={() => setSelectedDuration(3)}
            className={selectedDuration === 3 ? 'active' : ''}>
            3 {intl`simulator_result_8`}
          </button>
        </div>
      </div>
    )
  }

  const NewCatSimulatorResults = () => {
    return (
      <div className='results'>
        <div className='label'>{intl`simulator_result_4`}</div>
        <div className='notice'>{intl`simulator_result_5`}</div>
        <div className='number'>
          {simData.oneYearInterest}
          <span>€</span>
        </div>
        <div className='duration'>1 {intl`simulator_result_7`}</div>
      </div>
    )
  }

  return (
    <MainCSS isCoachSimulator={isCoachSimulator} withCoachProjects={withCoachProjects}>
      <div className='psa-blockContent'>
        <div className='psa-blockLegend'>{intl`coach_overview_title_4`}</div>
        {simCATError && <div>Service indisponible</div>}
        {simCATData && (
          <>
            <Field fieldId='label' className='field floatLabelField'>
              <input
                className='psa-inputText'
                name='capital'
                id='capital'
                type='text'
                maxLength='32'
                title={intl`Votre capital`}
                data-required
                aria-required='true'
                onChange={(event) => {
                  onInputChange(event)
                }}
              />
              <label htmlFor='capital'>{intl`Votre capital`}*</label>
              <div className='symbol'>€</div>
            </Field>
            {simData && (isNewCatEnabled ? <NewCatSimulatorResults /> : <SimulatorResults />)}
          </>
        )}
      </div>
    </MainCSS>
  )
}

export default SimulatorCAT
