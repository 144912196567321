import React from 'react'
import styled, { CSS_VARS } from 'services/styled'

const CustomRadioContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  user-select: none;

  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    appearance: none;
    background-color: transparent !important;
    cursor: pointer;
    z-index: 1;
  }

  input + span {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 2px solid ${CSS_VARS.color_brand_secondary};
  }

  input:checked + span {
    background-color: ${CSS_VARS.color_brand_secondary};
    &:after {
      display: inline-block;
    }
  }

  input:focus + span {
    outline: 2px solid #005fcc;
  }

  span:after {
    display: none;
    font-size: 30px;
    color: ${CSS_VARS.color_white};
    content: '×';
  }
`

export default props => {
  return (
    <CustomRadioContainer>
      <input type="radio" {...props} />
      <span />
    </CustomRadioContainer>
  )
}
