import React, { useState } from 'react'
import styled, { CSS_VARS } from 'services/styled'

const TabCSS = styled.li`
  flex: 1;
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 5px;
  background-color: ${CSS_VARS.color_background};
  font-weight: ${CSS_VARS.weight_bold};
  font-size: ${CSS_VARS.size_xxs};
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  &.psaTab__item--active {
    background-color: ${CSS_VARS.color_white};
  }

  @media only screen and (min-width: ${CSS_VARS.min_desktop_screen}) {
    flex: 1 1 auto;
    padding: 16px 5px;
    font-weight: ${CSS_VARS.weight_semi_bold};
    font-size: ${CSS_VARS.size_s};
  }
`

export const Tab = ({ title, onClick, active = false }) => {
  const onClickTab = (e) => {
    e.preventDefault();
    onClick(title)
  }

  return (
    <TabCSS className={`psaTab__item ${active ? 'psaTab__item--active' : ''}`} onClick={onClickTab}>
      {title}
    </TabCSS>
  )
}

const MainCSS = styled.div`
  .psaTab__list {
    padding: 0;
    display: flex;
    align-items: center;
  }
  .psaTab__content {
    padding: 20px 10px 12px;
    background-color: ${CSS_VARS.color_white};
  }
  @media (min-width: ${CSS_VARS.min_tablet_screen}) {
    .psaTab__content {
      padding: 40px 70px 20px;
    }
  }
`

const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(children[0].props.title)

  return (
    <MainCSS>
      <ul className='psaTab__list'>
        {children.map(tab => {
          const { title } = tab.props
          return (
            <Tab
              key={title}
              title={title}
              onClick={tab => setActiveTab(tab)}
              active={title === activeTab}
            />
          );
        })}
      </ul>
      <div className='psaTab__content'>
        {children.map(tab => {
          if (tab.props.title !== activeTab) return undefined;
          return tab.props.children;
        })}
      </div>
    </MainCSS>
  )
}

export default Tabs
