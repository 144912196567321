import React, { Component } from 'react'
import DOMPurify from 'dompurify'
import { connect } from 'services/store'
import { hideNotification } from 'services/store/actions'
import { Notification, Modal } from 'uic'

class NotificationModal extends Component {
  state = {
    cleanHTML: '',
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.notification || {}).text !== (this.props.notification || {}).text) {
      const { notification } = this.props
      if (notification) {
        const { text, html } = notification
        if (text && html) {
          this.setState({
            cleanHTML: DOMPurify.sanitize(text),
          })
        }
      }
    }
  }

  onClose = () => {
    if (this.props.onModalClose) {
      this.props.onModalClose()
    }
    hideNotification()
  }

  render() {
    if (!this.props.notification) return null

    const {
      notification: { type, style, title, text, html = false, buttons },
    } = this.props
    return (
      <Modal onClose={this.onClose}>
        <Notification type={type} style={style} title={title}>
          {text &&
            (html ? (
              <div dangerouslySetInnerHTML={{ __html: this.state.cleanHTML }} />
            ) : (
              <p>{text}</p>
            ))}

          {buttons && buttons.length && (
            <div className='Notification-actions'>
              {buttons.map((button, index) => {
                return (
                  <button
                    key={button.title}
                    type='button'
                    onClick={button.type === 'cancel' ? this.onClose : button.handler}
                    className={`psa-button Notification-action ${
                      index === 0 ? 'isPrimary' : 'isSkull'
                    }`}>
                    {button.title}
                  </button>
                )
              })}
            </div>
          )}
        </Notification>
      </Modal>
    )
  }
}

const mapSateToProps = ({ view: { notification, onModalClose } }) => {
  return {
    notification,
    onModalClose,
  }
}

export default connect(mapSateToProps)(NotificationModal)
