import React from 'react'
import PdfFormPageTemplate from './PdfFormPageTemplate'

const PdfFormFundsOrigin = ({
  title = 'MR',
  lastName = '',
  birthName = '',
  firstName = '',
  clientRef = '',
  accountNo = '',
}) => {
  const renderPage1Content = () => {
    return (
      <React.Fragment>
        <section className='pdf-section'>
          <h2 className='pdf-section-title'>Identification / Situation</h2>
          <div className='pdf-section-content'>
            <div className='pdf-checkboxes-inline'>
              <div className='pdf-checkbox'>
                <input type='checkbox' defaultChecked={title === 'MME'} />
                <span className='case' />
                <label>Mme</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' defaultChecked={title === 'MLLE'} />
                <span className='case' />
                <label>Mlle</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' defaultChecked={title === 'MR'} />
                <span className='case' />
                <label>M</label>
              </div>
            </div>
            <div className={`pdf-form-line ${lastName !== '' ? 'completed' : ''}`}>
              Nom&nbsp;: <span>{lastName}</span>
            </div>
            <div className={`pdf-form-line ${firstName !== '' ? 'completed' : ''}`}>
              Prénom(s)&nbsp;: <span>{firstName}</span>
            </div>
            <div className={`pdf-form-line ${clientRef !== '' ? 'completed' : ''}`}>
              Référence&nbsp;client&nbsp;: <span>{clientRef}</span>
            </div>
            <div className={`pdf-form-line ${accountNo !== '' ? 'completed' : ''}`}>
              Numéro&nbsp;de&nbsp;compte&nbsp;livret&nbsp;d'épargne&nbsp;Distingo&nbsp;:{' '}
              <span>{accountNo}</span>
            </div>
          </div>
        </section>

        <section className='pdf-section'>
          <h2 className='pdf-section-title'>
            Origine des fonds{' '}
            <span>
              (obligatoire pour tout versement supérieur à 50 000 € ou sur demande de DISTINGO Bank)
            </span>
          </h2>
          <div className='pdf-section-content'>
            <ol className='pdf-highlighted-list-ol'>
              <li>
                <span>Caractéristique</span>
                <div className='pdf-amounts-table'>
                  <div className='pdf-amounts-cell pdf-amounts-right'>Date de versement&nbsp;:</div>
                  <div className='pdf-amounts-cell'>
                    <input type='text' />
                  </div>
                  <div className='pdf-amounts-cell pdf-amounts-right'>
                    Montant du versement&nbsp;:
                  </div>
                  <div className='pdf-amounts-cell'>
                    <input type='text' defaultValue='€        ' />
                  </div>
                </div>
              </li>
              <li>
                <span>Quelle est l'origine des fonds</span>
                <div className='pdf-amounts-table'>
                  <div className='pdf-checkbox-cell'>
                    <div className='pdf-checkbox'>
                      <input type='checkbox' />
                      <span className='case' />
                      <label>Vente de bien immobilier</label>
                    </div>
                  </div>
                  <div className='pdf-amounts-cell pdf-amounts-right'>Montant&nbsp;:</div>
                  <div className='pdf-amounts-cell'></div>
                </div>
                <div className='pdf-amounts-table'>
                  <div className='pdf-checkbox-cell'>
                    <div className='pdf-checkbox'>
                      <input type='checkbox' />
                      <span className='case' />
                      <label>Vente de bien mobilier</label>
                    </div>
                  </div>
                  <div className='pdf-amounts-cell pdf-amounts-right'>Montant&nbsp;:</div>
                  <div className='pdf-amounts-cell'></div>
                </div>
                <div className='pdf-amounts-table'>
                  <div className='pdf-checkbox-cell'>
                    <div className='pdf-checkbox'>
                      <input type='checkbox' />
                      <span className='case' />
                      <label>Cession de titres</label>
                    </div>
                  </div>
                  <div className='pdf-amounts-cell pdf-amounts-right'>Montant&nbsp;:</div>
                  <div className='pdf-amounts-cell'></div>
                </div>
                <div className='pdf-amounts-table'>
                  <div className='pdf-checkbox-cell'>
                    <div className='pdf-checkbox'>
                      <input type='checkbox' />
                      <span className='case' />
                      <label>Épargne</label>
                    </div>
                  </div>
                  <div className='pdf-amounts-cell pdf-amounts-right'>Montant&nbsp;:</div>
                  <div className='pdf-amounts-cell'></div>
                </div>
                <div className='pdf-amounts-table'>
                  <div className='pdf-checkbox-cell'>
                    <div className='pdf-checkbox'>
                      <input type='checkbox' />
                      <span className='case' />
                      <label>Héritage&nbsp;/&nbsp;Donation</label>
                    </div>
                  </div>
                  <div className='pdf-amounts-cell pdf-amounts-right'>Montant&nbsp;:</div>
                  <div className='pdf-amounts-cell'></div>
                </div>
                <div className='pdf-amounts-table'>
                  <div className='pdf-checkbox-cell'>
                    <div className='pdf-checkbox'>
                      <input type='checkbox' />
                      <span className='case' />
                      <label>Gain aux jeux</label>
                    </div>
                  </div>
                  <div className='pdf-amounts-cell pdf-amounts-right'>Montant&nbsp;:</div>
                  <div className='pdf-amounts-cell'></div>
                </div>
                <div className='pdf-amounts-table'>
                  <div className='pdf-checkbox-cell'>
                    <div className='pdf-checkbox'>
                      <input type='checkbox' />
                      <span className='case' />
                      <label>Indemnisation&nbsp;/&nbsp;dommages intérêts</label>
                    </div>
                  </div>
                  <div className='pdf-amounts-cell pdf-amounts-right'>Montant&nbsp;:</div>
                  <div className='pdf-amounts-cell'></div>
                </div>
                <div className='pdf-amounts-table'>
                  <div className='pdf-checkbox-cell'>
                    <div className='pdf-checkbox'>
                      <input type='checkbox' />
                      <span className='case' />
                      <label>Prêt</label>
                    </div>
                  </div>
                  <div className='pdf-amounts-cell pdf-amounts-right'>Montant&nbsp;:</div>
                  <div className='pdf-amounts-cell'></div>
                </div>
                <div className='pdf-amounts-table'>
                  <div className='pdf-checkbox-cell'>
                    <div className='pdf-checkbox'>
                      <input type='checkbox' />
                      <span className='case' />
                      <label>Autres&nbsp;: (préciser)</label>
                    </div>
                  </div>
                  <div className='pdf-amounts-cell pdf-amounts-right'>Montant&nbsp;:</div>
                  <div className='pdf-amounts-cell'></div>
                </div>
              </li>
            </ol>
          </div>

          <div className='pdf-section-content'>
            <div className='pdf-checkboxes-inline'>
              <div className='pdf-checkbox'>
                <p>Somme investie&nbsp;:</p>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>Partiellement</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>Intégralement</label>
              </div>
            </div>
            <br />
            <p className='pdf-bold'>
              Pour tout montant supérieur à 100 000 € ou si DISTINGO Bank vous en fait la demande,
              nous vous remercions de bien vouloir joindre un justificatif probant.
            </p>
          </div>
        </section>

        <section className='pdf-section'>
          <div className='pdf-section-content largest'>
            <p className='pdf-highlighted'>Préciser l'objectif poursuivi&nbsp;:</p>
            <div className='pdf-checkboxes-inline'>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>Constituer un capital pour ma retraite</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>Bénéficier de revenus en cas de besoin</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>Autres (préciser) ..............</label>
              </div>
            </div>
          </div>
        </section>

        <section className='pdf-section sign-section'>
          <div className='pdf-sign-date-place'>
            Fait à ......................................... Le ......./......../........
            <br />
            <br />
          </div>

          <table className='pdf-sign-table smallest single'>
            <tbody>
              <tr>
                <td>Signature du titulaire du compte</td>
              </tr>
            </tbody>
          </table>
        </section>
      </React.Fragment>
    )
  }

  return (
    <div>
      {/* page 1 */}
      <PdfFormPageTemplate
        title="déclaration d'origine des fonds"
        isFirstPage
        headerCustomText={
          <React.Fragment>
            <span>À renvoyer signée à&nbsp;:</span>
            <span className='pdf-bold'>DISTINGO - TSA 84321 - 92563 RUEIL MALMAISON CEDEX</span>
            <span>ou via la rubrique dediee de la messagerie de votre Espace privé</span>
          </React.Fragment>
        }
        formRef='DOF042023'>
        {renderPage1Content()}
      </PdfFormPageTemplate>
    </div>
  )
}

export default PdfFormFundsOrigin
