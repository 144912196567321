import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'services/store'
import { clearViewState as clear, setState } from 'services/store/actions'

export default function clearViewState(BaseComponent) {
  const ClearViewState = ({ _path, location }) => {
    const [isInitialized, setIsInitialized] = useState(false)

    useEffect(
      () => {
        const componentDidMount = () => {
          if (_path) {
            clear()
          }
        }
        componentDidMount()
      },
      // We don't have exhaustive deps here because we want to run the effect only on mount
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )
    useEffect(() => {
      const componsantWillUpdate = () => {
        if (!isInitialized) {
          if (!_path) {
            setState({ _path: location })
          } else if (_path === location) {
            setIsInitialized(true)
          }
        }
      }
      componsantWillUpdate()
    }, [isInitialized, _path, location])

    return isInitialized ? <BaseComponent /> : null
  }

  const mapStateToProps = ({ view: { _path }, history: { location } }) => {
    return {
      _path,
      location,
    }
  }

  return withRouter(connect(mapStateToProps, true)(ClearViewState))
}
