import React, { Component } from 'react'
import styled, { CSS_VARS } from 'services/styled'

const AutocompleteWrapper = styled.div`
  position: relative;
  z-index: 0;
  flex: 1;


  /*label {
    position: absolute;
    z-index: 10;
    top: -10px;
    left: 10px;
    padding: 0 5px;
    color: #333;
    font-weight: 400;
    transform: translate(0, 26px) scale(1);
    transform-origin: center left;
    transition: all 0.3s ease-in;
  }
  input:focus + label,
  input.filled + label {
    display: block;
    color: #000;
    font-weight: bold;
    background-color: ${CSS_VARS.color_white};
    transform: translate(0, 0) scale(0.75);
  }
  input[readonly] + label {
    color: #000;
    font-weight: bold;
    background-color: #e7e7e7;
    transform: translate(0, 0) scale(0.75);
  }*/
  .no-suggestions {
    color: ${CSS_VARS.color_grey};
    padding: 0.5rem;
  }

  .suggestions {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 50px;
    width: 100%;
    max-height: 14.2rem;
    list-style: none;
    margin-top: 0;
    overflow-y: auto;
    padding-left: 0;
    background-color: ${CSS_VARS.color_white};
    border: 1px solid ${CSS_VARS.color_input_background};
  }

  .suggestions li {
    padding: 0.5rem 1rem;
    color: ${CSS_VARS.color_brand_primary};
  }

  .suggestions li.suggestion-active,
  .suggestions li:hover {
    background-color: ${CSS_VARS.color_brand_secondary};
    color: ${CSS_VARS.color_white};
    cursor: pointer;
    font-weight: 700;
  }
`

class Autocomplete extends Component {
  constructor(props) {
    super(props)

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: this.props.initValue
    }
  }

  onBlur = (e) => {
    const targetInput = e.target
    if (targetInput.value === '') {
      targetInput.classList.remove("filled")
    } else {
      targetInput.classList.add("filled")
    }
    if (this.state.filteredSuggestions.length === 0) {
      this.setState({
        userInput: ''
      }, () => {
        targetInput.classList.remove("filled")
      })
    }
  }

  onChange = e => {
    const { suggestions } = this.props
    const userInput = e.currentTarget.value

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.label.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    )

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value
    })
  }

  onClick = e => {
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    }, () => {
      if (this.props.callback) {
        this.props.callback()
      }
    })
  }

  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state

    // User pressed the enter key
    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion].label
      }, () => {
        if (this.props.callback) {
          this.props.callback()
        }
      })
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 })
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 })
    }
  }

  render() {
    const { id, label, displayValue='label' } = this.props
    const { activeSuggestion, filteredSuggestions, showSuggestions, userInput } = this.state
    let suggestionsListComponent

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className='suggestions'>
            {filteredSuggestions.map((suggestion, index) => {
              let className
              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = 'suggestion-active'
              }
              return (
                <li className={className} key={`${suggestion.code}_${index}`} onClick={this.onClick}>
                  {suggestion[displayValue]}
                </li>
              )
            })}
          </ul>
        )
      } else {
        suggestionsListComponent = (
          <div className='no-suggestions'>
            <em>Aucun résultat</em>
          </div>
        )
      }
    }

    return (
      <AutocompleteWrapper className='autocomplete'>
        <input
          id={id}
          className='psa-inputText'
          className={`psa-inputText ${userInput ? 'filled' : ''}`}
          type='text'
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          onBlur={this.onBlur}
          value={userInput}
          autoComplete="off"
        />
        <label htmlFor={id}>{label}</label>
        {suggestionsListComponent}
      </AutocompleteWrapper>
    );
  }
}

export default Autocomplete
