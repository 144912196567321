import Requester from 'services/api/Requester'
import BORequester from 'services/api/BORequester'
import { showNotification, showOtpInput } from 'services/store/actions'
import utils from 'services/utils'
import intl from 'services/intl'
import { getErrorMessage } from 'services/misc'
import { _API_URLS } from 'services/constants'

class CopartisRequester extends Requester {
  static session = null

  constructor() {
    super()

    CopartisRequester.session = this.session = performance.now()
    this.baseUrl = process.env.REACT_APP_COPARTIS_URL

    if (utils.app.get('session')) {
      let token = ''
      const { idToken, tempToken } = utils.app.get('session')
      // tempToken from KYC
      token = idToken || tempToken || ''
      this.headers = {
        Accept: 'application/json', // needed for IE11
        Authorization: `Bearer ${token}`,
      }
    }
  }

  format([wsError, wsResponse]) {
    let response = {
      data: null,
      error: false,
      isCanceled: false,
    }

    // Stop the current process when page changing
    if (CopartisRequester.session !== this.session) {
      response.error = true
      response.isCanceled = true
      return response
    }

    if (CopartisRequester.session) {
      // Track corpartis API calls here
      this.BORequester = new BORequester()
      const trackerData = {
        platform: 'web',
      }
      if (!wsError) {
        trackerData.url = wsResponse.request.responseURL
        trackerData.http_status_code = wsResponse.status
        this.BORequester.copartisServiceTracker(trackerData)
      } else if (wsError.response) {
        trackerData.url = wsError.response.request.responseURL
        trackerData.http_status_code = wsError.response.status
        this.BORequester.copartisServiceTracker(trackerData)
      }
      // End tracking
      if (wsError) {
        response.error = true

        if (wsError.response) {
          const {
            response: { status, data, request },
          } = wsError
          const textError = getErrorMessage(data)
          if (status === 401) {
            window.location.hash = '/logout'
          } else if (status >= 400 && status < 500) {
            showNotification({
              type: 'error',
              title: intl`error_title`,
              text: textError,
            })
            response.data = data
          } else if (status === 500) {
            if (process.env.REACT_APP_SHOW_ERRORS_CODES === 'true') {
              // dev & preprod env : show error
              showNotification({
                type: 'error',
                title: 'erreur 500',
                text: `${data.error} - ${data.message} - ${request.responseURL}`,
              })
            } else {
              showNotification({
                type: 'error',
                title: intl`error_title`,
                text: textError,
              })
            }
          } else {
            response.data = data
          }
        }
      } else {
        response.data = wsResponse.status === 204 ? null : wsResponse.data
      }
    }

    return response
  }

  async secureOperation(operation, route, requestBody, onError, onSuccess, closeCallback) {
    let config

    if (operation === 'contact') {
      config = {
        method: 'GET',
        route: `${route}&resend=false`,
        headers: this.headers,
      }
    } else if (operation === 'portability') {
      config = {
        method: 'GET',
        route: `${route}Request?resend=false&sendViaEmail=false`,
        headers: this.headers,
      }
    } else {
      config = {
        method: 'POST',
        route: `${route}?validateOnly=true`,
        headers: this.headers,
        data: requestBody,
      }
    }

    const getCode = await this.fetch(config)
    const response = this.format(getCode)
    if (!response.error) {
      const responseData = response?.data ?? {}
      let requiredType = ''
      if (responseData.requiredAuthorization) {
        const { detail } = responseData?.requiredAuthorization || {}
        requiredType = detail
      } else if (responseData.detail) {
        requiredType = responseData.detail
      }

      if (requiredType === 'TAN_REQUIRED' || requiredType === 'OTP_REQUIRED') {
        // show otp modal
        showOtpInput({
          codeType: requiredType,
          route,
          operation,
          data: requestBody,
          requester: this,
          onError,
          onSuccess,
          closeCallback,
        })
      } else if (requiredType === 'TAN_BLACKLIST') {
        // send validation without otp
        this.validateOperationWithoutCode(operation, route, requestBody, onError, onSuccess)
      } else {
        if (responseData.code) {
          showNotification({
            type: 'error',
            title: intl`error_title`,
            text: responseData.message || intl`generic_error`,
          })
        }
      }
      return response
    } else {
      closeCallback()
    }
  }

  async validateOperation(operation, route, data, code) {
    let config
    if (operation === 'contact') {
      config = {
        method: 'POST',
        route: `${route}&otp=${code}`,
        headers: this.headers,
      }
    } else if (operation === 'portability') {
      config = {
        method: 'GET',
        route: `${route}?otp=${code}`,
        headers: this.headers,
      }
    } else {
      config = {
        method: 'POST',
        route: `${route}?validateOnly=false&otp=${code}`,
        headers: this.headers,
        data,
      }
    }
    const validation = await this.fetch(config)
    const response = this.format(validation)
    return response
  }

  async validateOperationWithoutCode(operation, route, data, onError, onSuccess) {
    let config
    if (operation === 'contact') {
      config = {
        method: 'POST',
        route: `${route}&otp=''`,
        headers: this.headers,
      }
    } else if (operation === 'portability') {
      config = {
        method: 'GET',
        route: `${route}?otp=''`,
        headers: this.headers,
      }
    } else {
      config = {
        method: 'POST',
        route: `${route}?validateOnly=false&otp=''`,
        headers: this.headers,
        data,
      }
    }

    const validation = await this.fetch(config)
    const response = this.format(validation)
    if (response.error) {
      onError()
    } else {
      onSuccess(response.data)
    }
    // return response
  }

  /* =========== global data (lists) =========== */
  async getNationalities() {
    const response = await this.fetch({
      method: 'GET',
      route: _API_URLS.getNationalities,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    return this.format(response)
  }

  async getCountries() {
    const response = await this.fetch({
      method: 'GET',
      route: _API_URLS.getCountries,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    return this.format(response)
  }

  async getTowns(countryId, zipCode) {
    const response = await this.fetch({
      method: 'GET',
      route: _API_URLS.getTowns(countryId, zipCode),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    return this.format(response)
  }

  async getProfessions() {
    const response = await this.fetch({
      method: 'GET',
      route: _API_URLS.getProfessions,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    return this.format(response)
  }

  async getProfession(id) {
    const response = await this.fetch({
      method: 'GET',
      route: `${_API_URLS.getProfessions}/${id}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    return this.format(response)
  }

  async getBusinessSectors() {
    const response = await this.fetch({
      method: 'GET',
      route: _API_URLS.getBusinessSectors,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    return this.format(response)
  }

  async getSector(id) {
    const response = await this.fetch({
      method: 'GET',
      route: `${_API_URLS.getBusinessSectors}/${id}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    return this.format(response)
  }

  /* =========== contact =========== */
  async postEmailContact(data) {
    const response = await this.fetch({
      route: _API_URLS.postEmailContact,
      data: data,
    })

    return this.format(response)
  }

  async postCallContact(data) {
    const response = await this.fetch({
      route: _API_URLS.postCallContact,
      data: data,
    })

    return this.format(response)
  }

  /* =========== authentication =========== */
  async login(data) {
    const response = await this.fetch({
      route: _API_URLS.postToken,
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    return this.format(response)
  }

  async logout() {
    const response = await this.fetch({
      method: 'GET',
      route: _API_URLS.getRevoke,
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return this.format(response)
  }

  /* =========== home (accounts) =========== */
  async fetchAccounts() {
    const response = await this.fetch({
      method: 'GET',
      route: _API_URLS.getAccounts,
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return this.format(response)
  }

  async fetchAccountBalances(accountNo) {
    const response = await this.fetch({
      method: 'GET',
      route: _API_URLS.getBalances(accountNo),
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return this.format(response)
  }

  async fetchSavingAccount(accountNo) {
    const response = await this.fetch({
      method: 'GET',
      route: _API_URLS.getSaving(accountNo),
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return this.format(response)
  }

  async fetchAccountPromotionalOffers(accountNo) {
    const response = await this.fetch({
      method: 'GET',
      route: _API_URLS.getPromotionalOffers(accountNo),
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return this.format(response)
  }

  async fetchAccountSavingsInterests(accountNo) {
    const response = await this.fetch({
      method: 'GET',
      route: _API_URLS.getInterests(accountNo),
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return this.format(response)
  }

  async fetchAccountTransactions(accountNo) {
    const response = await this.fetch({
      method: 'GET',
      route: _API_URLS.getTransactions(accountNo),
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return this.format(response)
  }

  /* =========== person =========== */
  async getPerson() {
    const response = await this.fetch({
      method: 'GET',
      route: _API_URLS.getPerson,
      headers: this.headers,
    })

    return this.format(response)
  }

  async getSponsorEvents() {
    const response = await this.fetch({
      method: 'GET',
      route: _API_URLS.getSponsorEvents,
      headers: this.headers,
    })

    return this.format(response)
  }

  async sendSponsoredData(data) {
    const response = await this.fetch({
      method: 'POST',
      route: _API_URLS.postSponsoredData,
      headers: this.headers,
      data,
    })

    return this.format(response)
  }

  /* =========== transfers =========== */
  async fetchTransfers(cryptedAccountNumber) {
    const response = await this.fetch({
      method: 'GET',
      route: _API_URLS.getTransfers(cryptedAccountNumber),
      headers: this.headers,
    })

    return this.format(response)
  }

  async removeRecipient(id) {
    const response = await this.fetch({
      method: 'DELETE',
      route: _API_URLS.deleteBeneficiaries(id),
      headers: this.headers,
    })

    return this.format(response)
  }

  async fetchTransferAccounts() {
    const response = await this.fetch({
      method: 'GET',
      route: _API_URLS.getTransferAccounts,
      headers: this.headers,
    })

    return this.format(response)
  }

  async fetchBeneficiaries() {
    const response = await this.fetch({
      method: 'GET',
      route: _API_URLS.getBeneficiaries,
      headers: this.headers,
    })

    return this.format(response)
  }

  async createTransfer({ debitAccountNo, creditAccountNo, ...data }, isExternalTransfert) {
    const route = isExternalTransfert
      ? _API_URLS.postTransferExternal(debitAccountNo, creditAccountNo)
      : _API_URLS.postTransfer(debitAccountNo, creditAccountNo)

    const response = await this.fetch({
      method: 'POST',
      route,
      headers: this.headers,
      data,
    })

    return this.format(response)
  }

  /* =========== KYC =========== */
  async receiveOTP(target, unchangedValue, value) {
    let route
    let data
    if (target === 'email') {
      route = _API_URLS.postEmail
      data = `email=${value}`
    } else {
      route = _API_URLS.postPhones
      data = !unchangedValue
        ? target === 'mobile'
          ? `mobilePhone=${value}&privatePhone`
          : `mobilePhone&privatePhone=${value}`
        : target === 'mobile'
        ? `mobilePhone=${value}&privatePhone=${unchangedValue}`
        : `mobilePhone=${unchangedValue}&privatePhone=${value}`
    }
    const response = await this.fetch({
      method: 'GET',
      route: `${route}?${data}&resend=false`,
      headers: this.headers,
    })

    return this.format(response)
  }

  async confirmEditCoordinate(target, unchangedValue, value, OTP) {
    let route
    let data
    if (target === 'email') {
      route = _API_URLS.postEmail
      data = `email=${value}`
    } else {
      route = _API_URLS.postPhones
      data = !unchangedValue
        ? target === 'mobile'
          ? `mobilePhone=${value}&privatePhone`
          : `mobilePhone&privatePhone=${value}`
        : target === 'mobile'
        ? `mobilePhone=${value}&privatePhone=${unchangedValue}`
        : `mobilePhone=${unchangedValue}&privatePhone=${value}`
    }
    const response = await this.fetch({
      method: 'POST',
      route: `${route}?${data}&otp=${OTP}`,
      headers: this.headers,
    })

    return this.format(response)
  }

  /* =========== contracts =========== */
  async fetchDocs() {
    const response = await this.fetch({
      method: 'GET',
      route: _API_URLS.getDocuments,
      headers: this.headers,
    })

    return this.format(response)
  }

  /* =========== settings =========== */
  async createPassword(accessCode, oldPassword, newPassword) {
    const response = await this.fetch({
      method: 'POST',
      route: `${_API_URLS.postPassword}?id=${accessCode}&pin=${oldPassword}&pinNew=${newPassword}`,
      headers: this.headers,
    })

    return this.format(response)
  }

  async updateConsent(id, status) {
    const response = await this.fetch({
      method: 'PATCH',
      route: _API_URLS.patchConsents(id),
      headers: this.headers,
      data: {
        status,
      },
    })

    return this.format(response)
  }

  /* =========== openTermAccount =========== */
  async getDATRates() {
    const response = await this.fetch({
      method: 'GET',
      route: _API_URLS.getTauxCat,
      headers: this.headers,
    })

    return this.format(response)
  }

  async createCAT(data) {
    const response = await this.fetch({
      method: 'POST',
      route: _API_URLS.postCatCreate,
      headers: this.headers,
      data,
    })

    return this.format(response)
  }

  async editCAT(data) {
    const response = await this.fetch({
      method: 'PUT',
      route: _API_URLS.putCatUpdate,
      headers: this.headers,
      data,
    })

    return this.format(response)
  }

  async signCAT(data) {
    const response = await this.fetch({
      method: 'POST',
      route: _API_URLS.postCatSign,
      headers: this.headers,
      data,
    })

    return this.format(response)
  }

  /* =========== openMinorAccount =========== */
  async getRLShortDetail() {
    const response = await this.fetch({
      method: 'GET',
      route: _API_URLS.getRLShortDetail,
      headers: this.headers,
    })

    return this.format(response)
  }

  async getPromoCodes() {
    const response = await this.fetch({
      method: 'GET',
      route: _API_URLS.getPromotions,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    return this.format(response)
  }

  async createMinorAccount(data) {
    const response = await this.fetch({
      method: 'POST',
      route: _API_URLS.postMinorCreate,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data,
    })

    return this.format(response)
  }

  async editMinorAccount(data) {
    const response = await this.fetch({
      method: 'PUT',
      route: _API_URLS.putMinorUpdate,
      headers: this.headers,
      data,
    })

    return this.format(response)
  }

  async postMinorFiles(data) {
    const formData = Object.keys(data).reduce((formData, key) => {
      formData.append(key, data[key])
      return formData
    }, new FormData())

    const response = await this.fetch({
      route: _API_URLS.postMinorFiles,
      headers: this.header,
      data: formData,
    })
    return this.format(response)
  }

  async getMinorIframeUrl(data) {
    const response = await this.fetch({
      route: _API_URLS.postMinorSign,
      headers: this.header,
      data,
    })
    return this.format(response)
  }

  async signOffline(data) {
    const response = await this.fetch({
      route: _API_URLS.postMinorOfflineSign,
      headers: this.header,
      data,
    })
    return this.format(response)
  }

  /* =========== KYC =========== */
  async getKyc() {
    const response = await this.fetch({
      method: 'GET',
      route: _API_URLS.getKyc,
      headers: this.headers,
    })

    return this.format(response)
  }

  async updateKyc(data) {
    const response = await this.fetch({
      route: _API_URLS.postKyc,
      headers: this.headers,
      data,
    })

    return this.format(response)
  }

  /* =========== Portability data =========== */
  /*  async getOtpForPortabilityData() {
    const response = await this.fetch({
      method: 'GET',
      route: _API_URLS.getPortabilityDataOtp,
      headers: this.headers,
    })

    return this.format(response)
  }

  async getPortabilityData(otp) {
    const response = await this.fetch({
      method: 'GET',
      route: `${_API_URLS.getPortabilityData}?otp=${otp}`,
      headers: this.headers,
    })

    return this.format(response)
  } */

  /* =========== TAN =========== */
  async getTanStatus(token) {
    let tanHeader = this.headers
    if (token) {
      tanHeader = {
        Accept: 'application/json', // needed for IE11
        Authorization: `Bearer ${token}`,
      }
    }
    const mockOption = utils.app.get('mockOption', false)
    const mockRequests = mockOption && mockOption !== 'none'

    const response = await this.fetch({
      method: 'GET',
      route: _API_URLS.getTanStatus,
      headers: tanHeader,
      mockRequests,
    })

    return this.format(response)
  }

  async registerTanPhone(data) {
    const mockOption = utils.app.get('mockOption', false)
    const mockRequests = mockOption && mockOption !== 'none'

    const response = await this.fetch({
      method: 'POST',
      route: _API_URLS.postRegisterTanPhone,
      headers: this.headers,
      data,
      mockRequests,
    })

    return this.format(response)
  }

  async registerTanService(tan) {
    const mockOption = utils.app.get('mockOption', false)
    const mockRequests = mockOption && mockOption !== 'none'

    const response = await this.fetch({
      method: 'POST',
      route: `${_API_URLS.postRegisterTanService}/${tan}`,
      headers: this.headers,
      mockRequests,
    })

    return this.format(response)
  }

  async activateTanService(tan) {
    const mockOption = utils.app.get('mockOption', false)
    const mockRequests = mockOption && mockOption !== 'none'

    const response = await this.fetch({
      method: 'POST',
      route: `${_API_URLS.postActivateTanService}/${tan}`,
      headers: this.headers,
      mockRequests,
    })

    return this.format(response)
  }

  async getTempPassword(tokenRecaptcha, id, data) {
    const response = await this.fetch({
      method: 'POST',
      route: `${_API_URLS.postPasswordRescue}/${id}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        tokenRecaptcha,
      },
      data,
    })

    return this.format(response)
  }
}

export default CopartisRequester
