/**
 * Service for making all API requests
 */

import axios from 'axios'

export default class Requester {
  // static mock = null
  static lastRequest = null

  apiKey = null
  baseUrl = null
  headers = {}

  fetch({
    route = null,
    headers = {},
    withoutHeaders = false, // Clear the default headers
    params = {}, // params in the URL
    data = {}, // body of the request
    method = 'post',
    responseType = 'text',
    customHeader = false,
    mockRequests = false,
  }) {
    const axiosInstance = axios.create({
      baseURL: this.baseUrl,
      headers: customHeader ? headers : Object.assign({}, this.headers, headers),
    })
    Requester.lastRequest = Date.now()

    if (process.env.REACT_APP_AUTHORIZE_MOCK === 'true' && mockRequests) {
      const mockRequests = require('./useMock').default
      mockRequests(axiosInstance)
    }

    return axiosInstance({
      method,
      url: route,
      params,
      data,
      responseType,
      withCredentials: true,
    })
      .then(function (response) {
        // console.log('Requester', response)
        return [null, response]
      })
      .catch(function (error) {
        // console.log('Requester error', error)
        return [error]
      })
  }
}
