import * as React from 'react'
import dialogPolyfill from 'dialog-polyfill'
import styled, { CSS_VARS } from 'services/styled'
import 'dialog-polyfill/dialog-polyfill.css'
import ErrorBoundary from 'uic/ErrorBoundary'
import { ReactComponent as CrossIcon } from 'assets/svg/cross.svg'

const MainCSS = styled.dialog`
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  padding: 10px;
  width: 100%;
  height: fit-content;
  max-height: 100%;
  border: none;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (min-width: ${CSS_VARS.min_tablet_screen}) {
    max-width: 600px;
    max-height: calc(100vh - 80px);
    width: 100%;
  }

  &::backdrop,
  & + .backdrop {
    /* native */
    background-color: rgba(0, 0, 0, 0.4);
  }

  .closeModal {
    position: absolute;
    right: 18px;
    top: 18px;

    > svg {
      stroke: #000000;
      width: 20px;
      height: 20px;
    }
  }

  && .Notification-content {
    margin-bottom: 0;
    padding: 0;
    &.tan p {
      font-size: 16px;
    }
    .Notification-actions {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin: 20px 20px 0;
      .Notification-action {
        // flex: 1;
        margin: 0 10px;
        padding: 5px 10px 5px 16px;
        min-height: 36px;
        line-height: 1.1;
      }
    }
  }
`

class Modal extends React.Component {
  constructor(props) {
    super(props)

    this.dialog = React.createRef()

    this.state = {
      titleId: Date.now(),
    }
  }

  componentDidMount() {
    window.document.body.style.overflow = 'hidden'
    dialogPolyfill.registerDialog(this.dialog.current)
    this.dialog.current.showModal()
    const title = this.dialog.current.querySelector('h2')
    if (title) title.id = this.state.titleId
  }

  componentWillUnmount() {
    window.document.body.style.overflow = 'auto'
    this.dialog.current.close()
  }

  render() {
    return (
      <MainCSS
        role='dialog'
        aria-modal='true'
        aria-labelledby={this.state.titleId}
        onClick={this.globalCloseModal}
        onClose={this.props.onClose}
        className={`Modal ${this.props.className || ''}`}
        ref={this.dialog}>
        <ErrorBoundary>
          <div role='document' onClick={this.stopCloseModal}>
            <button type='button' className='closeModal' onClick={this.props.onClose}>
              <span className='sr-only'>Fermer la modale</span>
              <CrossIcon />
            </button>
            {this.props.children}
          </div>
        </ErrorBoundary>
      </MainCSS>
    )
  }

  globalCloseModal = () => {
    this.props.onClose()
  }

  stopCloseModal = (event) => {
    event.stopPropagation()
  }
}

export default Modal
