import React from 'react'
import PdfFormPageTemplate from './PdfFormPageTemplate'

const PdfFormExemption = ({
  title = 'MR',
  lastName = '',
  birthName = '',
  firstName = '',
  clientRef = '',
  accountNo = '',
}) => {
  const renderPage1Content = (nextYear) => {
    return (
      <React.Fragment>
        <section className='pdf-section'>
          <h2 className='pdf-section-title'>Identification / Situation</h2>
          <div className='pdf-section-content'>
            <span className='pdf-highlighted'>Le titulaire,</span>
            <div className='pdf-checkboxes-inline'>
              <div className='pdf-checkbox'>
                <input type='checkbox' defaultChecked={title === 'MME'} />
                <span className='case' />
                <label>Mme</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' defaultChecked={title === 'MLLE'} />
                <span className='case' />
                <label>Mlle</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' defaultChecked={title === 'MR'} />
                <span className='case' />
                <label>M</label>
              </div>
            </div>
            <div className={`pdf-form-line ${lastName !== '' ? 'completed' : ''}`}>
              Nom&nbsp;: <span>{lastName}</span>
            </div>
            <div className={`pdf-form-line ${birthName !== '' ? 'completed' : ''}`}>
              Nom&nbsp;de&nbsp;naissance&nbsp;: <span>{birthName}</span>
            </div>
            <div className={`pdf-form-line ${firstName !== '' ? 'completed' : ''}`}>
              Prénom(s)&nbsp;: <span>{firstName}</span>
            </div>
            <div className={`pdf-form-line ${clientRef !== '' ? 'completed' : ''}`}>
              Référence&nbsp;client&nbsp;: <span>{clientRef}</span>
            </div>
            <div className={`pdf-form-line ${accountNo !== '' ? 'completed' : ''}`}>
              Numéro&nbsp;de&nbsp;compte&nbsp;livret&nbsp;d'épargne&nbsp;Distingo&nbsp;:{' '}
              <span>{accountNo}</span>
            </div>
          </div>
          <div className='pdf-section-content'>
            <br />
            <span className='pdf-highlighted'>Et le co-titulaire éventuel,</span>
            <div className='pdf-checkboxes-inline'>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>Mme</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>Mlle</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>M</label>
              </div>
            </div>
            <div className='pdf-form-line'>
              Nom&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Nom&nbsp;de&nbsp;naissance&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Prénom(s)&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Référence&nbsp;client&nbsp;: <span></span>
            </div>
            <br />
            <div className='pdf-checkboxes-inline'>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>
                  Et l'(es) enfant(s) mineur(s) dont je suis représentant légal et titulaire(s) d'un
                  Livret Distingo.
                </label>
              </div>
            </div>
          </div>
        </section>

        <section className='pdf-section'>
          <h2 className='pdf-section-title'>
            attestation sur l'honneur<sup className='pdf-sup'>(1)</sup>
          </h2>
          <div className='pdf-section-content'>
            <p className='pdf-text'>
              Je (nous) demande (demandons) à être dispensé(e)(s) de prélèvement forfaitaire à la
              source de l'impôt sur le revenu, lorsqu'il est applicable sur les revenus de
              placements à revenu fixe (intérêts) qui me sont versés par DISTINGO Bank.
            </p>
            <p className='pdf-text'>
              <br />
              À cette fin, j' (nous) atteste (attestons) sur l'honneur que je (nous) remplis
              (remplissons) les conditions pour pouvoir bénéficier de cette dispense. À savoir que
              mon revenu fiscal de référence de l’année N-1 est inférieur à&nbsp;: 25.000 euros pour
              les contribuables célibataires, divorcés ou veufs / 50.000 euros pour les couples
              ayant une imposition commune.
            </p>
            <p className='pdf-text'>
              <br />
              Je (nous) reconnais (reconnaissons) être informé(e)(s) que je m'expose (nous nous
              exposons) à des sanctions fiscales en cas de déclaration inexacte.
            </p>
          </div>
        </section>

        <section className='pdf-section sign-section'>
          <div className='pdf-sign-date-place'>
            Fait à ......................................... Le ......./......../........
            <br />
            <br />
          </div>
          <table className='pdf-sign-table double lowmargin'>
            <tbody>
              <tr>
                <td>Signature du titulaire du compte</td>
                <td>Signature du co-titulaire du compte</td>
              </tr>
            </tbody>
          </table>
        </section>

        <section className='pdf-section'>
          <div className='pdf-section-content'>
            <p className='pdf-bottom-mention'>
              (1) La présente demande de dispense de prélèvement ne vaut que pour l'année {nextYear}{' '}
              et doit, le cas échéant, être renouvelée chaque année, avant le 30 novembre de l'année
              N-1 pour l'année suivante (par ex. avant le 30/11/{nextYear} pour l’année{' '}
              {nextYear + 1}).
            </p>
          </div>
        </section>
      </React.Fragment>
    )
  }

  const actualYear = new Date().getFullYear()

  const changeDate = new Date(2019, 11, 17, 0, 0, 0)
  changeDate.setFullYear(actualYear)

  const refYear = new Date() > changeDate ? actualYear : actualYear - 1
  const deadLineYear = new Date() > changeDate ? actualYear + 1 : actualYear
  const nextYear = new Date() > changeDate ? actualYear + 2 : actualYear + 1

  return (
    <div>
      {/* page 1 */}
      <PdfFormPageTemplate
        title='demande de dispense de prélèvement'
        title2={`sur les intérêts acquis en ${nextYear}`}
        isFirstPage
        headerCustomText={
          <React.Fragment>
            <span className='low'>
              Attestation sur l'honneur à renvoyer signée{' '}
              <strong>avant le 30 novembre {deadLineYear}</strong>
              <br />
              pour une prise en compte sur les intérêts acquis sur l'année {nextYear} à&nbsp;:
            </span>
            <span className='pdf-bold'>DISTINGO - TSA 84321 - 92563 RUEIL MALMAISON CEDEX</span>
            <span>ou via la rubrique dediee de la messagerie de votre Espace privé</span>
          </React.Fragment>
        }
        formRef='DPS042023'>
        {renderPage1Content(nextYear)}
      </PdfFormPageTemplate>
    </div>
  )
}

export default PdfFormExemption
