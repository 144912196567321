import React, { useEffect } from 'react'
import { connect } from 'services/store'
import intl from 'services/intl'

import step3Img from 'assets/images/icones/icon-password-validated.png'

const LostPasswordStep3 = ({ goToLoginScreen }) => {
  useEffect(() => {
    const removeScriptTag = (id) => {
      const scriptElement = document.querySelector(`#${id}`)
      const element = document.querySelector('.grecaptcha-badge')

      if (scriptElement) {
        scriptElement.parentElement.removeChild(scriptElement)
      }
      if (element) {
        element.parentElement.removeChild(element)
      }
    }
    removeScriptTag('recaptcha-key')
  }, [])

  return (
    <div className='stepComponent step4'>
      <div className='stepComponent-wrapper'>
        <div className='stepComponent-content'>
          <h2 className='stepComponent-title'>{intl`forgotten_password_step_3_title`}</h2>
          <div className='stepComponent-image-wrapper'>
            <img className='stepComponent-image' src={step3Img} alt='' />
          </div>
          <div>
            <p className='stepComponent-instructions'>{intl`forgotten_password_step_3_text_1`}</p>
            <p className='stepComponent-instructions'>{intl`forgotten_password_step_3_text_2`}</p>
          </div>
        </div>
        <div className='stepComponent-actions'>
          <button
            type='button'
            className='stepComponent-action stepComponent-action-ternary'
            onClick={goToLoginScreen}>
            {intl`forgotten_password_step_3_cta_1`}
          </button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ view: { goToLoginScreen } }) => {
  return {
    goToLoginScreen,
  }
}

export default connect(mapStateToProps)(LostPasswordStep3)
