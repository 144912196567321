import React from 'react'
import ReactTooltip from 'react-tooltip'

import intl from 'services/intl'
import utils from 'services/utils'
import styled, { CSS_VARS } from 'services/styled'

const MainCSS = styled.div`
  margin-bottom: 30px;
  padding: 50px 26px 20px;
  text-align: center;

  .Book-notice {
    margin-top: 14px;
    padding: 0 20px;
    font-size: ${CSS_VARS.size_xs};
  }
  .Book-nb {
    padding: 0 20px;
    font-size: ${CSS_VARS.size_xs};
    text-align: center;
  }
  .Book-nminimum {
    color: ${CSS_VARS.color_product_primary};
    font-weight: ${CSS_VARS.weight_bold};
  }
  .Book-balance {
    font-size: ${CSS_VARS.size_xl};
    font-weight: ${CSS_VARS.weight_medium};
  }
  .Book-subscriptionCatBalance {
    margin-top: 0;
    font-size: 15px;
    font-weight: ${CSS_VARS.weight_semi_bold};
    > span {
      display: inline-block;
      &:first-child {
        margin-right: 10px;
      }
      &:last-child {
        color: ${CSS_VARS.color_product_primary};
      }
    }
  }
  .Book-subscriptionCatBalanceAmount {
    position: relative;

    .psa-tooltip-trigger {
      position: absolute;
      right: -30px;
      top: 0;
    }
  }
  &.green {
    .Book-notice {
      font-weight: ${CSS_VARS.weight_semi_bold};
    }
    .Book-nminimum {
      color: ${CSS_VARS.color_brand_primary};
    }
    .Book-balance {
      font-size: ${CSS_VARS.size_xl};
      font-weight: ${CSS_VARS.weight_semi_bold};
    }
  }
  @media only screen and (min-width: ${CSS_VARS.min_desktop_screen}) {
    .Book-notice,
    .Book-nb {
      padding: 0 100px;
    }
  }
`

const OpenTermBookBlock = ({ book, type = 'standard' }) => {
  return (
    <MainCSS className={`psa-blockContent Book ${type}`}>
      {type !== 'green' && (
        <ReactTooltip effect='solid' className='psa-tooltip' globalEventOff='click' />
      )}

      <h2 className='psa-blockLegend isBook'>{intl`subscription_CAT_step1_text_4`}</h2>
      {type === 'flash' ? (
        <p className='Book-notice'>
          {intl`subscription_CAT_flash_step1_text_1`}{' '}
          <span className='Book-nminimum'>1010 euros</span>{' '}
          {intl`subscription_CAT_flash_step1_text_2`}
        </p>
      ) : type === 'green' ? (
        <p className='Book-notice'>
          {intl`subscription_CAT_green_step1_text_1`}{' '}
          <span className='Book-nminimum'>1010 euros</span>{' '}
          {intl`subscription_CAT_green_step1_text_2`}
        </p>
      ) : (
        <p className='Book-notice'>
          {intl`subscription_CAT_step1_text_1`} <span className='Book-nminimum'>1010 euros</span>{' '}
          {intl`subscription_CAT_step1_text_2`}
        </p>
      )}

      <div className='Book-balance'>{utils.numberFormat(book.balance.realtimeAmount)}</div>
      {type === 'green' ? (
        <div className='Book-subscriptionCatBalance'>
          <span>{intl`subscription_CAT_green_step1_text_3`}</span>
          <span className='Book-subscriptionCatBalanceAmount'>
            {utils.numberFormat(book.balance.catSubscriptionsAmount)}
          </span>
        </div>
      ) : (
        <div className='Book-subscriptionCatBalance'>
          <span>{intl`subscription_CAT_step1_text_2bis`}</span>
          <span className='Book-subscriptionCatBalanceAmount'>
            {utils.numberFormat(book.balance.catSubscriptionsAmount)}
            <button
              type='button'
              className='psa-tooltip-trigger psa-tooltip-trigger-light'
              data-tip={intl`subscription_CAT_step1_text_2_info`}
            />
          </span>
        </div>
      )}
    </MainCSS>
  )
}

export default OpenTermBookBlock
