export default {
  closest(el, selector) {
    let matchesFn

      // find vendor prefix
    ;[
      'matches',
      'webkitMatchesSelector',
      'mozMatchesSelector',
      'msMatchesSelector',
      'oMatchesSelector',
    ].some(function (fn) {
      if (typeof document.body[fn] === 'function') {
        matchesFn = fn
        return true
      }
      return false
    })

    let parent

    // traverse parents
    while (el) {
      parent = el.parentElement
      if (parent && parent[matchesFn](selector)) {
        return parent
      }
      el = parent
    }

    return null
  },

  toUrlEncoded(obj) {
    return Object.keys(obj)
      .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k]))
      .join('&')
  },

  deepCamelcaseKeys(obj) {
    const objToStringToArr = JSON.stringify(obj).replace(/":/g, '":[S]').split('[S]')

    const ucFirst = function (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }

    const newString = objToStringToArr.reduce((acc, str) => {
      const m = str.match(/"([a-z]+_?)+":/g)

      return m
        ? acc.concat(
            str.replace(
              m[0],
              m[0].split('_').reduce((acc2, str, i) => {
                return i ? acc2 + ucFirst(str) : acc2 + str
              }, '')
            )
          )
        : acc + str
    }, '')

    return JSON.parse(newString)
  },

  // sessionStorage hooks
  app: {
    get(key, parse = true) {
      return parse ? JSON.parse(sessionStorage.getItem(key)) : sessionStorage.getItem(key)
    },

    set(key, value) {
      sessionStorage.setItem(key, typeof value === 'object' ? JSON.stringify(value) : value)
    },

    remove(key) {
      sessionStorage.removeItem(key)
    },

    getLocal(key, parse = true) {
      return parse ? JSON.parse(localStorage.getItem(key)) : localStorage.getItem(key)
    },

    setLocal(key, value) {
      localStorage.setItem(key, typeof value === 'object' ? JSON.stringify(value) : value)
    },

    removeLocal(key) {
      localStorage.removeItem(key)
    },
  },

  numberFormat(amount) {
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(
      parseFloat(amount)
    )
  },

  dateFormat(date) {
    if (date instanceof Date) {
      return `${date.getDate()}.${
        date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
      }.${date.getFullYear()}`
    } else {
      const arr = date.split('T')[0].split(date.indexOf('-') === -1 ? '/' : '-')
      return `${arr[2]}.${arr[1]}.${arr[0]}`
    }
  },

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        return resolve(reader.result)
      }
      reader.onerror = (error) => reject(error)
    })
  },

  getUrlParts(url) {
    // run against regex
    const matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i)
    if (matches && matches[1]) {
      const splitMatches = url.split(matches[1])
      const split1 = splitMatches[1] || ''
      const split = split1.split('#')
      const path = split[1] || ''
      return {
        hostname: matches[1],
        path,
      }
    } else {
      return null
    }
  },

  getUrlQueryparam(string, param) {
    if (!string || !param) {
      return null
    }
    const splitString = string.split('?')
    const params = splitString[1] || ''

    const splitParams = params.split('&')
    if (splitParams.length) {
      let i
      let nbParams = splitParams.length
      for (i = 0; i < nbParams; i++) {
        const splitParam = splitParams[i].split('=')
        if (splitParam[0] && splitParam[0] === param) {
          return splitParam[1]
        }
      }
    }

    return null
  },

  isValidMobileFrenchPhoneNumber(phonenumber) {
    // src: https://en.wikipedia.org/wiki/Telephone_numbers_in_France

    const metropolitanFranceReg = new RegExp(/^(?:(?:\+|00)33|0)\s*[6|7](?:[\s.-]*\d{2}){4}$/)

    const reunionFranceReg = new RegExp(/^(?:(?:\+|00|0)((262692|262693)))(?:[\s.-]*\d{2}){3}$/)
    // 262, 263 = La Réunion, Mayotte and IO territories ;  +262 692 xx xx xx or +262 693 xx xx xx

    const guadeloupeFranceReg = new RegExp(/^(?:(?:\+|00|0)((590690|590691)))(?:[\s.-]*\d{2}){3}$/)
    // 590 = Guadeloupe, Saint-Martin et Saint-Barthélemy ;

    const guyaneFranceReg = new RegExp(/^(?:(?:\+|00|0)((594694)))(?:[\s.-]*\d{2}){3}$/)
    // 594 = French Guiana (Guyane) ;

    const martiniqueFranceReg = new RegExp(/^(?:(?:\+|00|0)((596696|596697)))(?:[\s.-]*\d{2}){3}$/)
    // 596 = Martinique

    const overseasFranceReg = new RegExp(/^(?:(?:\+|00|0)(508|681|687|689))(?:[\s.-]*\d{2}){3,4}$/)
    // 508 = Saint-Pierre-et-Miquelon
    // 681 = Wallis-et-Futuna ;
    // 687 = Nouvelle-Calédonie
    // 689 = French Polynesia

    return (
      !(phonenumber.match(metropolitanFranceReg) === null) ||
      !(phonenumber.match(reunionFranceReg) === null) ||
      !(phonenumber.match(guadeloupeFranceReg) === null) ||
      !(phonenumber.match(guyaneFranceReg) === null) ||
      !(phonenumber.match(martiniqueFranceReg) === null) ||
      !(phonenumber.match(overseasFranceReg) === null)
    )
  },

  isValidFixFrenchPhoneNumber(phonenumber) {
    const metropolitanFranceReg = new RegExp(/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/)

    const reunionFranceReg = new RegExp(/^(?:(?:\+|00|0)((262662|262663)))(?:[\s.-]*\d{2}){3}$/)
    // 262, 263 = La Réunion, Mayotte and IO territories ;

    const guadeloupeFranceReg = new RegExp(/^(?:(?:\+|00|0)((590590)))(?:[\s.-]*\d{2}){3}$/)
    // 590 = Guadeloupe, Saint-Martin et Saint-Barthélemy ;

    const guyaneFranceReg = new RegExp(/^(?:(?:\+|00|0)((594594)))(?:[\s.-]*\d{2}){3}$/)
    // 594 = French Guiana (Guyane) ;

    const martiniqueFranceReg = new RegExp(/^(?:(?:\+|00|0)((596596)))(?:[\s.-]*\d{2}){3}$/)
    // 596 = Martinique
    const overseasFranceReg = new RegExp(/^(?:(?:\+|00|0)(508|681|687|689))(?:[\s.-]*\d{2}){3,4}$/)
    // src: https://en.wikipedia.org/wiki/Telephone_numbers_in_France
    // 262, 263 = La Réunion, Mayotte and IO territories ; 508 = Saint-Pierre-et-Miquelon
    // 590 = Guadeloupe, Saint-Martin et Saint-Barthélemy ; 594 = French Guiana (Guyane) ; 596 = Martinique
    // 681 = Wallis-et-Futuna ; 687 = Nouvelle-Calédonie
    // 689 = French Polynesia
    return (
      !(phonenumber.match(metropolitanFranceReg) === null) ||
      !(phonenumber.match(reunionFranceReg) === null) ||
      !(phonenumber.match(guadeloupeFranceReg) === null) ||
      !(phonenumber.match(guyaneFranceReg) === null) ||
      !(phonenumber.match(martiniqueFranceReg) === null) ||
      !(phonenumber.match(overseasFranceReg) === null)
    )
  },
}
