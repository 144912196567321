import React from 'react'
import PdfFormPageTemplate from './PdfFormPageTemplate'

const PdfFormAddMandatory = ({
  title = 'MR',
  lastName = '',
  birthName = '',
  firstName = '',
  clientRef = '',
  accountNo = '',
}) => {
  const renderPage1Content = () => {
    return (
      <React.Fragment>
        <section className='pdf-section'>
          <div className='pdf-section-content'>
            <span className='pdf-highlighted'>Je (nous) soussigné(e)(s),</span>
          </div>
          <h2 className='pdf-section-title'>Le titulaire</h2>
          <div className='pdf-section-content'>
            <div className='pdf-checkboxes-inline'>
              <div className='pdf-checkbox'>
                <input type='checkbox' defaultChecked={title === 'MME'} />
                <span className='case' />
                <label>Mme</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' defaultChecked={title === 'MLLE'} />
                <span className='case' />
                <label>Mlle</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' defaultChecked={title === 'MR'} />
                <span className='case' />
                <label>M</label>
              </div>
            </div>
            <div className={`pdf-form-line ${lastName !== '' ? 'completed' : ''}`}>
              Nom&nbsp;: <span>{lastName}</span>
            </div>
            <div className={`pdf-form-line ${birthName !== '' ? 'completed' : ''}`}>
              Nom&nbsp;de&nbsp;naissance&nbsp;: <span>{birthName}</span>
            </div>
            <div className={`pdf-form-line ${firstName !== '' ? 'completed' : ''}`}>
              Prénom(s)&nbsp;: <span>{firstName}</span>
            </div>
            <div className={`pdf-form-line ${clientRef !== '' ? 'completed' : ''}`}>
              Référence&nbsp;client&nbsp;: <span>{clientRef}</span>
            </div>
            <div className={`pdf-form-line ${accountNo !== '' ? 'completed' : ''}`}>
              Numéro&nbsp;de&nbsp;compte&nbsp;livret&nbsp;d'épargne&nbsp;Distingo&nbsp;:{' '}
              <span>{accountNo}</span>
            </div>
            <div className='pdf-form-line'>
              Adresse&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Adresse&nbsp;email&nbsp;: <span></span>
            </div>
          </div>
        </section>
        <section className='pdf-section'>
          <h2 className='pdf-section-title'>Le co-titulaire</h2>
          <div className='pdf-section-content'>
            <div className='pdf-checkboxes-inline'>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>Mme</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>Mlle</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>M</label>
              </div>
            </div>
            <div className='pdf-form-line'>
              Nom&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Nom&nbsp;de&nbsp;naissance&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Prénom(s)&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Référence&nbsp;client&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Adresse&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Adresse&nbsp;email&nbsp;: <span></span>
            </div>
          </div>
          <div className='pdf-section-content'>
            <span className='pdf-highlighted space-top'>
              Ci-après désigné(s) «&nbsp;le(s) Mandant(s)&nbsp;»
            </span>
          </div>
        </section>

        <section className='pdf-section'>
          <h2 className='pdf-section-title'>Donne(ons) pouvoir à&nbsp;:</h2>
          <div className='pdf-section-content'>
            <div className='pdf-checkboxes-inline'>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>Mme</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>Mlle</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>M</label>
              </div>
            </div>
            <div className='pdf-form-line'>
              Nom&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Nom&nbsp;de&nbsp;naissance&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Prénom(s)&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Lieu&nbsp;de&nbsp;naissance&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line short'>
              Date&nbsp;de&nbsp;naissance&nbsp;: <span></span>&nbsp;&nbsp;&nbsp;&nbsp;/<span></span>
              &nbsp;&nbsp;&nbsp;&nbsp;/<span></span>
            </div>
            <div className='pdf-form-line'>
              Adresse&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Adresse&nbsp;email&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Téléphone&nbsp;: <span></span>
            </div>
          </div>
          <div className='pdf-section-content'>
            <span className='pdf-highlighted space-top'>
              Ci-après désigné «&nbsp;le Mandataire&nbsp;»
            </span>
          </div>

          <div className='pdf-section-content'>
            <p className='pdf-bold'>
              {`Pour, en mon (notre) nom et pour mon (notre) compte, sous réserve de dispositions légales et/ou réglementaires, effectuer toutes opérations et actes entrant dans le cadre du compte sur Livret n°${
                accountNo !== '' ? accountNo : '.........................................'
              } dont je suis titulaire (dont nous sommes co-titulaires), ouvert dans les livres de DISTINGO Bank, et ce quel que soit le moyen utilisé (écrit, télématique, Informatique, téléphonique, etc), dans les mêmes conditions que le(s) Mandant(s), et notamment`}
              &nbsp;:
            </p>
            <ul className='pdf-list space-top'>
              <li>
                faire tous les dépôts ou retraits de sommes sur le compte, dans les limites
                autorisées par le contrat, en donner ou retirer quittances et décharges,
              </li>
              <li>émettre tous ordres de débit par virement,</li>
              <li>signer tout acte ou contrat, donner toute instruction,</li>
              <li>
                se faire communiquer toute pièce et tout renseignement relatif au compte et en
                général, faire toutes opérations se rattachant à l'usage normal du compte dans
                l'intérêt et sous la responsabilité du (des) Mandant(s).
              </li>
              <li>
                Toutefois, le Mandataire ne pourra clôturer le compte qu'avec mon (notre) accord
                exprès.
              </li>
            </ul>
          </div>
        </section>
      </React.Fragment>
    )
  }

  const renderPage2Content = () => {
    return (
      <React.Fragment>
        <section className='pdf-section'>
          <div className='pdf-section-content'>
            <p className='pdf-highlighted'>La présente procuration demeurera valable jusqu'à</p>
            <ul className='pdf-list'>
              <li>renonciation par le Mandataire,</li>
              <li>
                révocation expresse par le Mandant (ou un des Mandants en cas de compte collectif)
                notifiée par lettre recommandée avec avis de réception. <br />
                Dans ce cas, le(s) Mandant(s) en informera(ont) le Mandataire sans délai. Le(s)
                Mandant(s) reste(nt) tenu(s) vis-à-vis de DISTINGO Bank pour toutes opérations
                ordonnées par le Mandataire jusqu'à ce que DISTINGO Bank ait connaissance de cette
                révocation,
              </li>
              <li>
                en cas de décès d'un des Mandants ou du Mandataire, porté à la connaissance de
                DISTINGO Bank,
              </li>
              <li>
                en cas de tutelle ou curatelle d'un des Mandants ou du Mandataire, portée à la
                connaissance de DISTINGO Bank,
              </li>
              <li>en cas de révocation judiciaire,</li>
              <li>
                à l'initiative de DISTINGO Bank informant le(s) Mandant(s) qu'elle n'agrée plus le
                Mandataire,
              </li>
              <li>automatiquement en cas de clôture du compte.</li>
            </ul>
            <p>
              L'ancien Mandataire ne pourra alors plus effectuer aucune opération sur le compte du
              (des) Mandant(s), ni obtenir de renseignements sur ledit compte, même au titre de la
              période durant laquelle la procuration lui avait été conférée.
            </p>
            <br />
            <p className='pdf-bold'>
              DISTINGO Bank se réserve le droit de ne pas agréer la personne proposée comme
              Mandataire, sous réserve de motiver son refus. Elle peut également, en motivant son
              refus, refuser toute procuration dont la complexité ne serait pas compatible avec ses
              contraintes de gestion.
            </p>
            <br />
          </div>
          <div className='pdf-section-content'>
            <p className='pdf-highlighted'>
              Chaque signataire reconnaît et accepte expressément&nbsp;:
            </p>
            <ul className='pdf-list'>
              <li>les dispositions de la présente demande,</li>
              <li>
                que le(s) mandant(s) est (sont) engagé(s) par les actes et opérations réalisés par
                le Mandataire, ce dernier agissant sous son (leur) entière responsabilité, et
                qu’il(s) demeure(nt) ainsi responsable(s) desdits actes et opérations.
              </li>
              <li>
                que la présente demande, une fois acceptée par DISTINGO Bank, a valeur d'avenant à
                la convention de compte sur Livret dont les références sont précisées ci-dessus mais
                ne remet pas en cause les autres conditions particulières existantes, non contraires
                et non modifiées, applicables à ladite convention et qui subsistent donc&nbsp;;
              </li>
              <li>
                avoir pris connaissance et accepter sans réserve les Conditions Générales
                applicables au Livret et les Conditions Tarifaires de DISTINGO Bank en
                vigueur&nbsp;;
              </li>
              <li>disposer d'un exemplaire de chacun des documents et conditions susvisés.</li>
            </ul>
            <br />
            <p>
              Le Mandataire désigné déclare expressément que rien ne l'empêche d’exercer valablement
              sa mission, notamment qu'il est majeur et ne fait pas l'objet d'une mesure judiciaire
              de protection des incapables majeurs.
            </p>
          </div>
        </section>

        <section className='pdf-section sign-section'>
          <div className='pdf-sign-date-place'>
            Fait à ......................................... Le ......./......../........
          </div>
          <p className='pdf-sign-mention pdf-bold'>
            Toute demande ne comportant pas la signature de chaque Mandant et du Mandataire ainsi
            que les justificatifs d'identité et de domicile de ce dernier ne pourra être acceptée
            par DISTINGO Bank.
          </p>
          <table className='pdf-sign-table'>
            <tbody>
              <tr>
                <td width='33%'>
                  Signature du titulaire du compte précédée de la mention manuscrite «&nbsp;Bon pour
                  pouvoir&nbsp;»
                </td>
                <td width='33%'>
                  Signature du co-titulaire du compte précédée de la mention manuscrite «&nbsp;Bon
                  pour pouvoir&nbsp;»
                </td>
                <td width='33%'>
                  Signature du mandataire du compte précédée de la mention manuscrite «&nbsp;Bon
                  pour acceptation de pouvoir&nbsp;»
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </React.Fragment>
    )
  }

  const renderPage3Content = () => {
    return (
      <React.Fragment>
        <section className='pdf-section'>
          <h2 className='pdf-section-title'>Justificatifs</h2>
          <div className='pdf-section-content'>
            <p className='pdf-highlighted'>La signature du mandataire vaut spécimen de signature</p>
            <ul className='pdf-list'>
              <li>
                Joindre la photocopie d'une pièce d'identité en cours de validité du Mandataire avec
                mentions lisibles et photographie reconnaissable&nbsp;:
                <ul>
                  <li>Carte d'identité&nbsp;: recto/verso, ou</li>
                  <li>
                    Passeport&nbsp;: copie des pages faisant apparaître l'identité de la personne,
                    le numéro de passeport, le tampon de délivrance, la signature Mandataire, ou
                  </li>
                  <li>
                    Titre de séjour en France signé dont la date d'expiration est supérieure d'un
                    mois à la date de la présente demande&nbsp;: recto/verso.
                  </li>
                </ul>
              </li>
              <li>
                En complément, pour le cas où la pièce d'identité du Mandataire est à son nom de
                naissance, livret de famille ou extrait d'acte de mariage.
              </li>
              <li>
                Joindre la photocopie d'une pièce justificative de domicile au nom du Mandataire
                datant de moins de 3 mois&nbsp;:
                <ul>
                  <li>
                    Facture ou échéancier d'une compagnie d'électricité, de gaz, de téléphonie fixe,
                    d'internet, d'eau ou
                  </li>
                  <li>Attestation d'une maison de retraite.</li>
                  <li>
                    A défaut, une attestation d'hébergement avec copie du justificatif de domicile
                    et une copie d'une pièce d'identité en cours de validité (voir ci-dessus) au nom
                    de la personne qui vous héberge.
                  </li>
                </ul>
              </li>
            </ul>
            <br />
          </div>
        </section>
        <section className='pdf-section sign-section'>
          <div className='rgpd'>
            <p>
              <span className='pdf-bold'>PROTECTION DES DONNÉES&nbsp;:</span> les données collectées
              sont nécessaires au traitement de votre demande. Pour plus d’informations sur le
              traitement de vos données personnelles et ses finalités, vous reporter à l’article 6
              des Conditions Générales de DISTINGO Bank «&nbsp;CONFIDENTIALITÉ - PROTECTION DES
              DONNÉES&nbsp;».
            </p>
            <br />
            <p>
              Si je ne suis pas titulaire d'un Livret DISTINGO, par ailleurs, j’accepte de recevoir
              les offres de DISTINGO Bank par&nbsp;:
            </p>
            <br />
            <div>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>E-mail</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>SMS</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>Téléphone</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>Courrier</label>
              </div>
            </div>
            <br />
            <table className='pdf-sign-table single smallest'>
              <tbody>
                <tr>
                  <td>Signature du mandataire du compte</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </React.Fragment>
    )
  }

  return (
    <div>
      {/* page 1 */}
      <PdfFormPageTemplate
        title='délivrance de procuration ou'
        title2="«&nbsp;ajout d'un mandataire&nbsp;»"
        isFirstPage
        hasPagination
        totalPages={3}
        currentPage={1}
        hasLogoTri={false}
        hasAddress={false}
        refType='right'
        formRef='AJMAN042023'
        headerCustomText={
          <React.Fragment>
            <span>À renvoyer signée exclusivement par voie postale à&nbsp;:</span>
            <span className='pdf-bold'>DISTINGO - TSA 84321 - 92563 RUEIL MALMAISON CEDEX</span>
          </React.Fragment>
        }>
        {renderPage1Content()}
      </PdfFormPageTemplate>

      {/* page 2*/}
      <PdfFormPageTemplate
        title='délivrance de procuration ou'
        title2="«&nbsp;ajout d'un mandataire&nbsp;»"
        isFirstPage={false}
        hasPagination
        totalPages={3}
        currentPage={2}
        hasLogoTri={false}>
        {renderPage2Content()}
      </PdfFormPageTemplate>

      {/* page 3 */}
      <PdfFormPageTemplate
        title='délivrance de procuration ou'
        title2="«&nbsp;ajout d'un mandataire&nbsp;»"
        isFirstPage={false}
        hasPagination
        totalPages={3}
        currentPage={3}>
        {renderPage3Content()}
      </PdfFormPageTemplate>
    </div>
  )
}

export default PdfFormAddMandatory
