import React, { Component } from 'react'
import styled, { CSS_VARS } from 'services/styled'

const MainCSS = styled.div`
  .lds-dual-ring {
    display: inline-block;
    width: 34px;
    height: 34px;
  }
  .lds-dual-ring:after {
    content: ' ';
    display: inline-block;
    width: 26px;
    height: 26px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid ${CSS_VARS.color_brand_primary};
    border-color: ${CSS_VARS.color_brand_primary} transparent ${CSS_VARS.color_brand_primary}
      transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const Loader = () => (
  <MainCSS>
    <div className="lds-dual-ring" />
    <div>Veuillez patienter</div>
  </MainCSS>
)

export default Loader
