import React, { memo, Suspense } from 'react'
import { HashRouter as Router, Switch, Route, withRouter } from 'react-router-dom'
import 'dayjs/locale/fr'
import dayjs from 'dayjs'
import routes from 'routes'
import { storeProvider } from 'services/store'
import { GlobalStyle } from 'services/styled'
import { Loader, MainTemplate, SessionExpiration } from 'uic'
import NotificationModal from 'uic/NotificationModal.js'
import OTPModal from 'uic/OTPModal.js'

dayjs.locale('fr') // use French locale globally

class _OnChangeRoute extends React.Component {
  componentDidMount() {
    window.dataLayer.push({
      pageTitle: this.props.location.pathname.replace('/', '') || 'home',
    })
  }

  shouldComponentUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // Scroll to top
      window.scrollTo(0, 0)
      window.dataLayer.push({
        pageTitle: this.props.location.pathname.replace('/', '') || 'home',
      })
    }

    return false
  }

  render() {
    return null
  }
}

const OnChangeRoute = withRouter(_OnChangeRoute)

const App = memo(
  () => {
    return (
      <>
        <SessionExpiration />
        <GlobalStyle />
        <NotificationModal />
        <OTPModal />
        <Router>
          <MainTemplate />
          <OnChangeRoute />
          <Suspense fallback={<Loader color={'blue'} />}>
            <Switch>
              {routes.map(({ name: key, ...route }) => {
                return <Route key={key} {...route} />
              })}
            </Switch>
          </Suspense>
        </Router>
      </>
    )
  },
  () => true // Always return true to prevent updates
)

export default storeProvider(App)
