// Allows to define the route permissions (public, private or both) according to the user status

import React from 'react'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { connect } from 'services/store'
import intl from 'services/intl'
import utils from 'services/utils'
import ErrorBoundary from 'uic/ErrorBoundary'

function routePermissionsHoc(type, title, Component) {
  return function routePermissions({ isAuthenticated, location }) {
    if (type === 'public' && isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: '/',
            state: { from: location },
          }}
        />
      )
    } else if (type === 'private' && !isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      )
    } else {
      return (
        <ErrorBoundary>
          {title !== '' && (
            <Helmet titleTemplate={'%s | mon espace client DISTINGO Bank'}>
              <title>{intl(title)}</title>
            </Helmet>
          )}
          <Component />
        </ErrorBoundary>
      )
    }
  }
}

export default (type, title, Component) =>
  connect(({ location }) => {
    let token = ''
    if (utils.app.get('session')) {
      const { idToken } = utils.app.get('session')
      token = idToken || ''
    }

    return {
      isAuthenticated: Boolean(token),
      location,
    }
  })(routePermissionsHoc(type, title, Component))
