import React from 'react'
import PdfFormPageTemplate from './PdfFormPageTemplate'

const PdfFormAddCoTit = ({
  title = 'MR',
  lastName = '',
  birthName = '',
  firstName = '',
  clientRef = '',
  accountNo = '',
}) => {
  const renderPage1Content = () => {
    return (
      <React.Fragment>
        <section className='pdf-section'>
          <h2 className='pdf-section-title'>
            Demande de transformation d'un compte individuel en compte joint
          </h2>
          <div className='pdf-section-content'>
            <span className='pdf-highlighted'>Nous soussignés,</span>
            <div className='pdf-checkboxes-inline'>
              <div className='pdf-checkbox'>
                <input type='checkbox' defaultChecked={title === 'MME'} />
                <span className='case' />
                <label>Mme</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' defaultChecked={title === 'MLLE'} />
                <span className='case' />
                <label>Mlle</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' defaultChecked={title === 'MR'} />
                <span className='case' />
                <label>M</label>
              </div>
            </div>
            <div className={`pdf-form-line ${lastName !== '' ? 'completed' : ''}`}>
              Nom&nbsp;: <span>{lastName}</span>
            </div>
            <div className={`pdf-form-line ${birthName !== '' ? 'completed' : ''}`}>
              Nom&nbsp;de&nbsp;naissance&nbsp;: <span>{birthName}</span>
            </div>
            <div className={`pdf-form-line ${firstName !== '' ? 'completed' : ''}`}>
              Prénom(s)&nbsp;: <span>{firstName}</span>
            </div>
            <div className={`pdf-form-line ${clientRef !== '' ? 'completed' : ''}`}>
              Référence&nbsp;client&nbsp;: <span>{clientRef}</span>
            </div>
            <div className={`pdf-form-line ${accountNo !== '' ? 'completed' : ''}`}>
              Numéro&nbsp;de&nbsp;compte&nbsp;livret&nbsp;d'épargne&nbsp;Distingo&nbsp;:{' '}
              <span>{accountNo}</span>
            </div>
          </div>
          <div className='pdf-section-content'>
            <br />
            <span className='pdf-highlighted'>Et</span>
            <div className='pdf-checkboxes-inline'>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>Mme</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>Mlle</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>M</label>
              </div>
            </div>
            <div className='pdf-form-line'>
              Nom&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Nom&nbsp;de&nbsp;naissance&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Prénom(s)&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Lieu&nbsp;de&nbsp;naissance&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line short'>
              Date&nbsp;de&nbsp;naissance&nbsp;: <span></span>&nbsp;&nbsp;&nbsp;&nbsp;/<span></span>
              &nbsp;&nbsp;&nbsp;&nbsp;/<span></span>
            </div>
            <div className='pdf-form-line'>
              Adresse&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              &nbsp;<span></span>
            </div>
            <div className='pdf-form-line'>
              &nbsp;<span></span>
            </div>
            <div className='pdf-form-line'>
              Téléphone&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Adresse&nbsp;email&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Situation&nbsp;familiale&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Profession&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Secteur&nbsp;d'activité&nbsp;: <span></span>
            </div>

            <p className='pdf-text'>
              <br />
              Demandons la transformation du compte individuel dont les références sont précisées
              ci-dessus en compte joint ouvert à nos noms.
            </p>
          </div>
        </section>

        <section className='pdf-section'>
          <h2 className='pdf-section-title'>
            À cet effet nous joignons les documents, relatifs au co-titulaire, suivants :
          </h2>
          <div className='pdf-section-content'>
            <ul className='pdf-highlighted-list'>
              <div>
                <li>
                  {' '}
                  <span>
                    <strong>Une pièce d'identité&nbsp;:</strong>{' '}
                  </span>
                </li>
                <ul className='pdf-list'>
                  <li>carte d'identité en cours de validité&nbsp;: copie recto/verso, ou&nbsp;;</li>
                  <li>
                    passeport en cours de validité&nbsp;: copie des pages faisant apparaître votre
                    identité, le numéro de passeport, le tampon de délivrance, et votre signature,
                    ou&nbsp;;
                  </li>
                  <li>
                    titre de séjour en France, signé, dont la date d'expiration est supérieure d'au
                    moins un mois à la date de la présente demande&nbsp;: copie recto/verso.
                  </li>
                </ul>
                <p>DISTINGO Bank est susceptible de vous demander une 2ème pièce d'identité.</p>
                <br />
                <li>
                  <span>
                    <strong>
                      Un original du RIB d'un compte à vue ouvert au nom du co-titulaire dans un
                      établissement de crédit situé en France.
                    </strong>
                  </span>
                </li>
              </div>
            </ul>
          </div>
        </section>
      </React.Fragment>
    )
  }

  const renderPage2Content = () => {
    return (
      <React.Fragment>
        <section className='pdf-section'>
          <div className='pdf-section-content'>
            <ul className='pdf-highlighted-list'>
              <li>
                <span>
                  La photocopie d'une pièce justificative de domicile au nom du co-titulaire datant
                  de moins de 3 mois&nbsp;:
                </span>
                <div>
                  <ul className='pdf-list'>
                    <li>
                      Facture ou échéancier d'une compagnie d'électricité, de gaz, de téléphonie
                      fixe, d'internet, d’eau ou
                    </li>
                    <li>3 dernières quittances de loyer d'une agence immobilière ou</li>
                    <li>Attestation d'une maison de retraite</li>
                    <li>
                      À défaut, une attestation d’hébergement avec copie du justificatif de domicile
                      et une copie d’une pièce d’identité en cours de validité (voir ci-dessus) au
                      nom de la personne qui vous héberge
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <span>
                  Le document d'auto-certification disponible sur distingobank.fr, dûment complété
                  et signé par le co-titulaire.
                  <br />
                  <br />
                  <br />
                </span>
              </li>
            </ul>
            <p className='pdf-highlighted'>
              Chaque signataire reconnaît et accepte expressément&nbsp;:
            </p>
            <ul className='pdf-list'>
              <li>
                Que la présente demande, une fois acceptée par DISTINGO Bank, a valeur d’avenant à
                la convention de compte sur Livret dont les références sont précisées ci-dessus mais
                ne remet pas en cause les autres conditions particulières existantes, non contraires
                et non modifiées, applicables à ladite convention et qui subsistent donc&nbsp;;
              </li>
              <li>
                Qu'une demande de confirmation écrite de son accord leur sera adressée par DISTINGO
                Bank&nbsp;;
              </li>
              <li>
                Avoir pris connaissance et accepter sans réserve les Conditions Générales
                applicables au Livret et les Conditions Tarifaires de DISTINGO Bank en
                vigueur&nbsp;;
              </li>
              <li>Disposer d’un exemplaire de chacun des documents et conditions susvisés.</li>
            </ul>
          </div>
        </section>

        <section className='pdf-section sign-section'>
          <div className='pdf-sign-date-place'>
            Fait à ......................................... Le ......./......../........
          </div>
          <p className='pdf-sign-mention'>
            Toute demande ne comportant pas la signature des deux co-titulaires ne pourra être
            acceptée par DISTINGO Bank.
          </p>
          <table className='pdf-sign-table double smallest'>
            <tbody>
              <tr>
                <td>Signature du titulaire du compte</td>
                <td>Signature du co-titulaire du compte</td>
              </tr>
            </tbody>
          </table>
          <br />
          <div className='rgpd'>
            <p>
              <span className='pdf-bold'>PROTECTION DES DONNÉES&nbsp;:</span> les données collectées
              sont nécessaires au traitement de votre demande. Pour plus d’informations sur le
              traitement de vos données personnelles et ses finalités, vous reporter à l’art. 6 des
              Conditions Générales de DISTINGO Bank «&nbsp;CONFIDENTIALITÉ - PROTECTION DES
              DONNÉES&nbsp;».
            </p>
            <br />
            <p>
              J’accepte de recevoir les offres de DISTINGO Bank (notamment les offres de
              fidélisation réservées aux clients) par&nbsp;:
            </p>
            <br />
            <div>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>E-mail</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>SMS</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>Téléphone</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>Courrier</label>
              </div>
            </div>
            <br />
            <table className='pdf-sign-table single smallest'>
              <tbody>
                <tr>
                  <td>Signature du co-titulaire du compte</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </React.Fragment>
    )
  }

  return (
    <div>
      {/* page 1 */}
      <PdfFormPageTemplate
        title="demande d'ajout d'un co-titulaire"
        isFirstPage
        hasPagination
        totalPages={2}
        currentPage={1}
        formRef='AJCO042023'
        hasLogoTri
        headerCustomText={
          <React.Fragment>
            <span>À renvoyer signée exclusivement par voie postale à&nbsp;:</span>
            <span className='pdf-bold'>DISTINGO - TSA 84321 - 92563 RUEIL MALMAISON CEDEX</span>
          </React.Fragment>
        }>
        {renderPage1Content()}
      </PdfFormPageTemplate>

      {/* page 2*/}
      <PdfFormPageTemplate
        title="demande d'ajout d'un co-titulaire"
        isFirstPage={false}
        hasPagination
        totalPages={2}
        currentPage={2}
        formRef='AJCO042023'
        hasLogoTri>
        {renderPage2Content()}
      </PdfFormPageTemplate>
    </div>
  )
}

export default PdfFormAddCoTit
