import { filter } from 'lodash-es'
import * as XLSX from 'sheetjs-style'
import { defer, EMPTY, from, merge, of, zip } from 'rxjs'
import { flatMap, toArray } from 'rxjs/operators'
import dayjs from 'dayjs'
import {
  updateCacheAccounts,
  isCoachAvailableForUser,
  updateUserAccountTypes,
} from './store/actions'
import intl from 'services/intl'
import { PROFESSIONS, BUSINESS_SECTORS } from 'services/constants'

var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

export const getBooks = function (accounts) {
  if (!accounts) {
    return {}
  }

  const books = accounts.filter((account) => account.saving.accountType === 'LIV')

  books.sort(accountSortingOrder)

  return books[0]
}

export const getHolderBook = function (accounts) {
  if (!accounts) {
    return {}
  }

  let booksTit = []
  let booksCot = []
  let booksMan = []
  let booksRep = []

  for (const k in accounts) {
    if (accounts.hasOwnProperty(k)) {
      /* if (accounts[k].saving.accountType === 'LIV' && accounts[k].personRole === 'TIT') {
        book = accounts[k]
      } */
      if (accounts[k]?.saving.accountType === 'LIV') {
        if (accounts[k]?.personRole === 'TIT') {
          booksTit.push(accounts[k])
        } else if (accounts[k]?.personRole === 'COT') {
          booksCot.push(accounts[k])
        } else if (accounts[k]?.personRole === 'MAN') {
          booksMan.push(accounts[k])
        } else {
          booksRep.push(accounts[k])
        }
      }
    }
  }

  if (booksTit.length) {
    return booksTit[0]
  } else if (booksCot.length) {
    return booksCot[0]
  } else if (booksMan.length) {
    return booksMan[0]
  } else {
    return booksRep[0]
  }
}

export const getLivretTit = function (accounts) {
  if (!accounts) {
    return {}
  }

  let book = {}

  for (const k in accounts) {
    if (accounts.hasOwnProperty(k)) {
      if (accounts[k].saving.accountType === 'LIV' && accounts[k].personRole === 'TIT') {
        book = accounts[k]
      }
    }
  }

  return book
}

export const getAccountBalance = function (account) {
  if (!account) {
    return 0
  }

  const realtimeBalances = (account.balances || []).filter((b) => b.balanceType === 'XPCD')

  if (!realtimeBalances) {
    console.log('error getting realtimeBalances')
    return 0
  }

  const realtimeBalance = realtimeBalances[0]

  if (!realtimeBalance) {
    console.log('error getting realtimeBalance')
    return 0
  }

  return {
    realtimeAmount: (realtimeBalance.balanceAmount || {}).amount,
    transferAmount: realtimeBalance.allowedAmountForCashTransfer,
    catSubscriptionsAmount: realtimeBalance.allowedAmountForCatSubscriptions,
  }
}

export const getAccountInterests = function (account, _interests) {
  if (!_interests) {
    return {
      standard: 0,
      bonif: 0,
    }
  }
  if (account.saving.accountType === 'LIV') {
    const standResult = filter(_interests, { title: 'Intérêts courus standards bruts' })[0]
    const bonifResult = filter(_interests, { title: 'Intérêts courus bonifiés bruts' })[0]

    return {
      standard: (standResult && standResult.grossInterestAmount) || 0,
      bonif: (bonifResult && bonifResult.grossInterestAmount) || 0,
    }
  } else {
    const standResult = _interests[0]
    return {
      standard: (standResult && standResult.grossInterestAmount) || 0,
      bonif: 0,
    }
  }
}

export const accountSortingOrder = function (a, b) {
  // Order according to the account types

  if (
    a.accountType === 'LIV' &&
    (a.personRole === 'TIT' ||
      (a.personRole === 'COT' && b.personRole !== 'TIT') ||
      (a.personRole === 'REP' && b.personRole !== 'COT' && b.personRole !== 'TIT') ||
      (a.personRole === 'MAN' &&
        b.personRole !== 'REP' &&
        b.personRole !== 'COT' &&
        b.personRole !== 'TIT'))
  ) {
    return -1
  } else {
    return 0
  }
}

// group Accounts by roles & then by type
export const groupAccounts = function (accounts) {
  const ownerAccounts = {
    livrets: [],
    cats: [],
  } // for TIT and COT roles
  const minorAccounts = {
    livrets: [],
    cats: [],
  }
  const proxyAccounts = {
    livrets: [],
    cats: [],
  } // for MAN role

  if (!accounts || accounts.length === 0) {
    return { ownerAccounts, minorAccounts, proxyAccounts }
  }

  accounts.forEach((account) => {
    switch (account.personRole) {
      case 'TIT':
      case 'COT':
        if (account.accountType === 'SAVING') {
          ownerAccounts.livrets.push(account)
        } else {
          ownerAccounts.cats.push(account)
        }
        break
      case 'REP':
        if (account.accountType === 'SAVING') {
          minorAccounts.livrets.push(account)
        } else {
          minorAccounts.cats.push(account)
        }
        break
      case 'MAN':
        if (account.accountType === 'SAVING') {
          proxyAccounts.livrets.push(account)
        } else {
          proxyAccounts.cats.push(account)
        }
        break
      default:
        if (account.accountType === 'SAVING') {
          ownerAccounts.livrets.push(account)
        } else {
          ownerAccounts.cats.push(account)
        }
    }
  })

  const sortedOwnerAccounts = {
    livrets: ownerAccounts.livrets.sort((a, b) => {
      return getAccountBalance(b).realtimeAmount - getAccountBalance(a).realtimeAmount
    }),
    cats: ownerAccounts.cats.sort((a, b) => {
      return getAccountBalance(b).realtimeAmount - getAccountBalance(a).realtimeAmount
    }),
  }
  const sortedMinorAccounts = {
    livrets: minorAccounts.livrets.sort((a, b) => {
      return getAccountBalance(b).realtimeAmount - getAccountBalance(a).realtimeAmount
    }),
    cats: minorAccounts.cats.sort((a, b) => {
      return getAccountBalance(b).realtimeAmount - getAccountBalance(a).realtimeAmount
    }),
  }
  const sortedProxyAccounts = {
    livrets: proxyAccounts.livrets.sort((a, b) => {
      return getAccountBalance(b).realtimeAmount - getAccountBalance(a).realtimeAmount
    }),
    cats: proxyAccounts.cats.sort((a, b) => {
      return getAccountBalance(b).realtimeAmount - getAccountBalance(a).realtimeAmount
    }),
  }

  return {
    ownerAccounts: sortedOwnerAccounts,
    minorAccounts: sortedMinorAccounts,
    proxyAccounts: sortedProxyAccounts,
  }
}

export const getShortIban = function (iban) {
  return iban.split(' ').slice(-2).join(' ').replace(/\*/g, 'X')
}

export const getAccounts = function (copartisRequester) {
  let canHaveCoach = false
  let hasMinorAccounts = false
  let hasProxyAccounts = false
  const fetchAccounts = () => {
    const result = { data: null, error: false }

    return from(copartisRequester.fetchAccounts())
      .pipe(
        flatMap(({ data: accountsData, error: accountsError }) => {
          if (accountsError || !accountsData.length) {
            result.error = true
            return of(result)
          }

          const observables = accountsData.map((account) => {
            if (account.personRole === 'TIT' || account.personRole === 'COT') {
              canHaveCoach = true
            }
            if (account.personRole === 'REP') {
              hasMinorAccounts = true
            }
            if (account.personRole === 'MAN') {
              hasProxyAccounts = true
            }
            return zip(
              defer(() => copartisRequester.fetchAccountBalances(account.accountNo)),
              defer(() => copartisRequester.fetchSavingAccount(account.accountNo)),
              defer(() => copartisRequester.fetchAccountSavingsInterests(account.accountNo))
            ).pipe(
              flatMap(([r1, r2, r3]) => {
                const { data: balancesData, error: balancesError } = r1

                // In first, fetch the balances
                if (!balancesError) {
                  account.balances = balancesData

                  const { data: savingData, error: savingError } = r2

                  if (!savingError) {
                    account.saving = savingData

                    const { data: interestsData, error: interestsError } = r3

                    if (!interestsError) {
                      account.interests = interestsData
                    }
                  }

                  return of(account)
                }

                return EMPTY
              })
            )
          })

          return merge(...observables, 3) // Plays 2 observables concurently
        })
      )
      .pipe(toArray())
      .pipe(
        flatMap((accounts) => {
          result.data = accounts
          return of(result)
        })
      )
  }

  return from(fetchAccounts()).pipe(
    flatMap((accounts) => {
      // Treats account data
      if (accounts.error) {
        return EMPTY
      } else {
        // Saves to the cache
        updateCacheAccounts(accounts.data)
        isCoachAvailableForUser(canHaveCoach)
        updateUserAccountTypes({ hasMinorAccounts, hasProxyAccounts })
      }

      return of(accounts)
    })
  )
}

export const getAccountsPromise = async function (copartisRequester) {
  let canHaveCoach = false
  let hasMinorAccounts = false
  let hasProxyAccounts = false
  const fetchAccounts = async () => {
    const result = { data: null, error: false }
    const fetchedAccounts = await copartisRequester.fetchAccounts()
    if (fetchedAccounts.error) {
      result.error = true
      return result
    }
    const accountsData = fetchedAccounts?.data ?? []
    const accountsPromises = await accountsData.map(async (account) => {
      if (account.personRole === 'TIT' || account.personRole === 'COT') {
        canHaveCoach = true
      }
      if (account.personRole === 'REP') {
        hasMinorAccounts = true
      }
      if (account.personRole === 'MAN') {
        hasProxyAccounts = true
      }
      const accountDetails = await Promise.all([
        copartisRequester.fetchAccountBalances(account.accountNo),
        copartisRequester.fetchSavingAccount(account.accountNo),
        copartisRequester.fetchAccountSavingsInterests(account.accountNo),
      ])

      if (accountDetails) {
        const { data: balancesData, error: balancesError } = accountDetails?.[0]
        // In first, fetch the balances
        if (!balancesError) {
          account.balances = balancesData
          const { data: savingData, error: savingError } = accountDetails?.[1]
          if (!savingError) {
            account.saving = savingData
            const { data: interestsData, error: interestsError } = accountDetails?.[2]
            if (!interestsError) {
              account.interests = interestsData
            }
          }
        }
      }
      return account
    })
    const accountsArray = await Promise.all(accountsPromises)
    result.data = accountsArray
    return result
  }

  const accounts = await fetchAccounts()

  if (!accounts.error) {
    // Saves to the cache
    updateCacheAccounts(accounts.data)
    isCoachAvailableForUser(canHaveCoach)
    updateUserAccountTypes({ hasMinorAccounts, hasProxyAccounts })
  }
  return accounts
}

export const getClientNumber = function (personExtId) {
  // remove all zeros
  if (!personExtId) {
    return ''
  }
  const personInt = parseInt(personExtId, 10)
  if (isNaN(personInt)) {
    return personExtId
  } else {
    return '' + personInt
  }
}

export const getCoachClientNumber = function (personExtId) {
  // keep last 8 characters
  if (!personExtId) {
    return ''
  }
  return personExtId.slice(personExtId.length - 8)
}

export const filterProfessions = function (professions) {
  if (!professions || professions.length === 0) return []
  const professionsKeys = Object.keys(PROFESSIONS)
  const filteredProfessions = professions.reduce((acc, p) => {
    if (professionsKeys.indexOf(p.title) >= 0) {
      const i = professionsKeys.indexOf(p.title)
      acc.push({ ...p, label: PROFESSIONS[professionsKeys[i]] })
    }
    return acc
  }, [])
  return filteredProfessions
}

export const filterBusinessSectors = function (businessSectors) {
  if (!businessSectors || businessSectors.length === 0) return []
  const businessSectorsKeys = Object.keys(BUSINESS_SECTORS)
  const filteredBusinessSectors = businessSectors.reduce((acc, b) => {
    if (businessSectorsKeys.indexOf(b.title) >= 0) {
      const i = businessSectorsKeys.indexOf(b.title)
      acc.push({ ...b, label: BUSINESS_SECTORS[businessSectorsKeys[i]] })
    }
    return acc
  }, [])
  return filteredBusinessSectors
}

export const getLastSponsoredEvents = function (sponsorEvents) {
  if (!sponsorEvents || sponsorEvents.length === 0) return []

  const events = []
  sponsorEvents.map((event) => {
    const { firstName, lastName, sponsorShipBonus, dateInvitation, sponsorShipCode } = event
    const sponsoredEvent = {
      firstName,
      lastName,
      sponsorShipBonus,
      sponsorShipCode,
    }
    if (event.events.length) {
      // get infos from last event of array events
      const eventsFromNewestToOlder = event.events.sort((a, b) => {
        const dateArrayA = a.dateEvent ? a.dateEvent.split(' ') : []
        const dateA = dateArrayA.length ? dateArrayA[0] : a.dateEvent
        const dayjsA = dayjs(dateA, 'DD/MM/YYYY')
        const dateArrayB = b.dateEvent ? b.dateEvent.split(' ') : []
        const dateB = dateArrayB.length ? dateArrayB[0] : b.dateEvent
        const dayjsB = dayjs(dateB, 'DD/MM/YYYY')

        return dayjsA.isBefore(dayjsB) ? 1 : -1
      })

      sponsoredEvent.dateEvent = eventsFromNewestToOlder[0].dateEvent
      sponsoredEvent.codeEvent = eventsFromNewestToOlder[0].codeEvent
    } else {
      sponsoredEvent.dateEvent = dateInvitation
      sponsoredEvent.codeEvent = ''
    }
    events.push(sponsoredEvent)
  })
  return events || []
}

const createXlsAccountsSheet = function (workbook, rows) {
  /* generate worksheet */
  const worksheet = XLSX.utils.json_to_sheet(rows, {
    header: ['Compte', 'Numéro de compte', 'Solde/Capital'],
    origin: 'A3',
  })

  /* add title before array of accounts */
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [
      [
        'Votre situation financière au ' +
          dayjs().format('DD/MM/YYYY') +
          ' (sous réserve d’opérations en cours)',
      ],
    ],
    { origin: 'A1' }
  )

  /* style cells */
  const thinBorders = {
    top: { style: 'thin', color: { rgb: '00000000' } },
    bottom: { style: 'thin', color: { rgb: '00000000' } },
    left: { style: 'thin', color: { rgb: '00000000' } },
    right: { style: 'thin', color: { rgb: '00000000' } },
  }
  const titleStyle = {
    font: { name: 'Montserrat', bold: true, sz: 14 },
  }
  const headerStyle = {
    font: {
      name: 'Montserrat',
      bold: true,
      sz: 10,
      color: { rgb: '00FFFFFF' },
    },
    fill: {
      patternType: 'solid',
      fgColor: { rgb: '000F243E' },
      bgColor: { rgb: '000F243E' },
    },
    alignment: { horizontal: 'center' },
    border: thinBorders,
  }
  const cellStyle = {
    font: { name: 'Montserrat', sz: 11 },
    border: thinBorders,
  }

  /* apply styles on cells*/
  worksheet['A1'].s = titleStyle
  worksheet['A3'].s = headerStyle
  worksheet['B3'].s = headerStyle
  worksheet['C3'].s = headerStyle

  for (var i = 0; i < rows.length; i++) {
    const R = i + 3
    const a1_addr = XLSX.utils.encode_cell({ r: R, c: 0 })
    const a2_addr = XLSX.utils.encode_cell({ r: R, c: 1 })
    const a3_addr = XLSX.utils.encode_cell({ r: R, c: 2 })

    worksheet[a1_addr].s = cellStyle
    worksheet[a2_addr].s = cellStyle
    worksheet[a3_addr].s = cellStyle
  }

  /* calculate column width */
  const max_width1 = rows.reduce((w, r) => Math.max(w, r['Compte'].length), 70)
  const max_width2 = rows.reduce((w, r) => Math.max(w, r['Numéro de compte'].length), 24)
  worksheet['!cols'] = [{ wch: max_width1 }, { wch: max_width2 }, { wch: max_width2 }]
  /* merge first line cells */
  const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }]
  worksheet['!merges'] = merge

  return worksheet
}

const createXlsTransactionsSheet = function (workbook, rows, account) {
  /* generate worksheet */
  const worksheet = XLSX.utils.json_to_sheet(rows, {
    header: ['Date', 'Valeur', 'Libellé', 'Débit', 'Crédit'],
    origin: 'A3',
  })

  /* add title on line 1 */
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [
      [
        'Situation de votre compte ' +
          account.label +
          ' au ' +
          dayjs().format('DD/MM/YYYY') +
          ' (sous réserve d’opérations en cours)',
      ],
    ],
    { origin: 'A1' }
  )
  /* add RIB on line 2 */
  XLSX.utils.sheet_add_aoa(worksheet, [['RIB : ' + account.iban]], { origin: 'A2' })
  /* add total on last line */
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [
      [' ', ' ', ' ', ' ', ' '],
      ['Solde au ' + dayjs().format('DD/MM/YYYY') + ' : ', ' ', ' ', ' ', account.balance],
    ],
    { origin: -1 }
  )

  /* style cells */
  const thinBorders = {
    top: { style: 'thin', color: { rgb: '00000000' } },
    bottom: { style: 'thin', color: { rgb: '00000000' } },
    left: { style: 'thin', color: { rgb: '00000000' } },
    right: { style: 'thin', color: { rgb: '00000000' } },
  }
  const titleStyle = {
    font: { name: 'Montserrat', bold: true, sz: 14 },
  }
  const ribStyle = {
    font: { name: 'Montserrat', sz: 14 },
  }
  const headerStyle = {
    font: {
      name: 'Montserrat',
      bold: true,
      sz: 10,
      color: { rgb: '00FFFFFF' },
    },
    fill: {
      patternType: 'solid',
      fgColor: { rgb: '000F243E' },
      bgColor: { rgb: '000F243E' },
    },
    alignment: { horizontal: 'center' },
    border: thinBorders,
  }
  const cellStyle = {
    font: { name: 'Montserrat', sz: 10 },
    border: {
      left: { style: 'thin', color: { rgb: '00000000' } },
      right: { style: 'thin', color: { rgb: '00000000' } },
    },
  }
  const lastCellStyle = {
    font: { name: 'Montserrat', sz: 10 },
    border: {
      left: { style: 'thin', color: { rgb: '00000000' } },
      right: { style: 'thin', color: { rgb: '00000000' } },
      bottom: { style: 'thin', color: { rgb: '00000000' } },
    },
  }
  const lightBgStyle = {
    patternType: 'solid',
    fgColor: { rgb: '00D5E1EF' },
    bgColor: { rgb: '00D5E1EF' },
  }
  const totalLabelStyle = {
    border: {
      left: { style: 'thin', color: { rgb: '00000000' } },
      top: { style: 'thin', color: { rgb: '00000000' } },
      bottom: { style: 'thin', color: { rgb: '00000000' } },
    },
    fill: lightBgStyle,
    alignment: { horizontal: 'right' },
    font: { name: 'Montserrat', bold: true, italic: true, sz: 10 },
  }
  const totalAmountStyle = {
    font: { name: 'Montserrat', sz: 10 },
    border: {
      right: { style: 'thin', color: { rgb: '00000000' } },
      top: { style: 'thin', color: { rgb: '00000000' } },
      bottom: { style: 'thin', color: { rgb: '00000000' } },
    },
    fill: lightBgStyle,
    alignment: { horizontal: 'right' },
  }

  /* apply styles on cells*/
  worksheet['A1'].s = titleStyle
  worksheet['A2'].s = ribStyle
  worksheet['A3'].s = headerStyle
  worksheet['B3'].s = headerStyle
  worksheet['C3'].s = headerStyle
  worksheet['D3'].s = headerStyle
  worksheet['E3'].s = headerStyle

  const lastRow = rows.length + 5
  worksheet[`A${lastRow}`].s = totalLabelStyle
  worksheet[`B${lastRow}`].s = totalLabelStyle
  worksheet[`C${lastRow}`].s = totalLabelStyle
  worksheet[`D${lastRow}`].s = totalLabelStyle
  worksheet[`E${lastRow}`].s = totalAmountStyle

  for (var i = 0; i < rows.length; i++) {
    const R = i + 3
    const a1_addr = XLSX.utils.encode_cell({ r: R, c: 0 })
    const a2_addr = XLSX.utils.encode_cell({ r: R, c: 1 })
    const a3_addr = XLSX.utils.encode_cell({ r: R, c: 2 })
    const a4_addr = XLSX.utils.encode_cell({ r: R, c: 3 })
    const a5_addr = XLSX.utils.encode_cell({ r: R, c: 4 })

    let style = i === rows.length - 1 ? lastCellStyle : cellStyle
    if (i % 2 === 0) {
      style = { ...style, fill: lightBgStyle }
    }

    worksheet[a1_addr].s = { ...style, alignment: { horizontal: 'center' } }
    worksheet[a2_addr].s = { ...style, alignment: { horizontal: 'center' } }
    worksheet[a3_addr].s = style
    worksheet[a4_addr].s = { ...style, alignment: { horizontal: 'right' } }
    worksheet[a5_addr].s = { ...style, alignment: { horizontal: 'right' } }
  }

  /* calculate column width */
  const max_width1 = rows.reduce((w, r) => Math.max(w, r['Date'].length), 12)
  const max_width2 = rows.reduce((w, r) => Math.max(w, r['Libellé'].length), 70)
  const max_width3 = rows.reduce((w, r) => Math.max(w, r['Crédit'].length), 17)
  worksheet['!cols'] = [
    { wch: max_width1 },
    { wch: max_width1 },
    { wch: max_width2 },
    { wch: max_width3 },
    { wch: max_width3 },
  ]

  /* merge cells */
  const lastRowIndex = rows.length + 4
  const merge = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 4 } }, // first line
    { s: { r: 1, c: 0 }, e: { r: 1, c: 4 } }, // second line
    { s: { r: lastRowIndex, c: 0 }, e: { r: lastRowIndex, c: 3 } }, // last line
  ]
  worksheet['!merges'] = merge

  return worksheet
}

export const createXlsExport = function (accountsListRows, accountTransactionsRows) {
  /* create workbook */
  const workbook = XLSX.utils.book_new()
  if (!workbook.Props) workbook.Props = {}
  workbook.Props.Title = 'Comptes DISTINGO Bank'

  /* First sheet - accounts list */
  const mainWorksheet = createXlsAccountsSheet(workbook, accountsListRows)
  XLSX.utils.book_append_sheet(workbook, mainWorksheet, 'Vos comptes')

  /* livret sheet - transaction list */
  if (accountTransactionsRows.length) {
    for (var i = 0; i < accountTransactionsRows.length; i++) {
      const { account, transactionsRows } = accountTransactionsRows[i]
      const accountWorksheet = createXlsTransactionsSheet(workbook, transactionsRows, account)

      XLSX.utils.book_append_sheet(workbook, accountWorksheet, account.sheetName)
    }
  }

  /* create an XLSX file and try to save to comptes.xlsx */
  XLSX.writeFile(workbook, 'comptes-distingo-bank.xlsx')
}

export const getErrorMessage = (data = null) => {
  let textError = intl`generic_error`

  if (data) {
    if (data.code) {
      if (intl(`error_${data.code}`) === `error_${data.code}`) {
        // no translation of error code
        if (data.message) {
          textError = data.message
        } else {
          textError =
            process.env.REACT_APP_SHOW_ERRORS_CODES === 'true'
              ? `error_${data.code}`
              : intl`generic_error`
        }
      } else {
        // use translation of error code
        textError = intl(`error_${data.code}`)
      }
    } else if (data.errorCode) {
      // new password blocked after 3 tries
      if (data.errorDesc) {
        textError = data.errorDesc
      } else {
        textError =
          process.env.REACT_APP_SHOW_ERRORS_CODES === 'true'
            ? `error_${data.errorCode}`
            : intl`generic_error`
      }
    }
  }
  return textError
}
