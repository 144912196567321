import * as React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'services/store'
import styled, { CSS_VARS } from 'services/styled'
import { SideNav } from 'uic'
import { getClientNumber } from 'services/misc'
import utils from 'services/utils'
import { ReactComponent as LogoutIcon } from 'assets/svg/logout.svg'
import { ReactComponent as UserIcon } from 'assets/svg/user.svg'
import { ReactComponent as MenuIcon } from 'assets/svg/menu.svg'
import { ReactComponent as AgendaIcon } from 'assets/svg/agenda.svg'

import logo from 'assets/images/logos/logo_web_distingo@3x.png'
import intl from '../services/intl'

const MainCSS = styled.header`
  display: block;
  position: ${(props) =>
    (props.isAuthenticated && !props.isResetPassword) || props.isKYC ? 'relative' : 'absolute'};
  z-index: 2;
  width: 100%;
  background: ${(props) => (props.isTanEnrolmentPage ? CSS_VARS.color_tan : 'transparent')};

  .container {
    display: ${(props) => (props.isTanEnrolmentPage ? 'none' : 'block')};
    @media (min-width: ${CSS_VARS.min_desktop_screen}) {
      display: ${(props) =>
        props.isAuthenticated ||
        props.isKYC ||
        props.isLogin ||
        props.isResetPassword ||
        props.isTanEnrolmentPage
          ? 'block'
          : 'none'};
    }
    .goToMainContent {
      position: absolute;
      left: 50%;
      top: 5px;
      transform: translateX(-50%);
      color: ${CSS_VARS.color_white};
      font-size: 0;
      &:focus {
        font-size: inherit;
      }
    }
  }
`
const ContentCSS = styled.div`
  display: flex;
  justify-content: ${(props) => (props.isAuthenticated ? 'space-between' : 'center')};
  align-items: center;
  height: 70px;
  color: ${CSS_VARS.color_white};

  @media (min-width: ${CSS_VARS.min_desktop_screen}) {
    display: ${(props) =>
      (props.isAuthenticated && !props.isResetPassword) || props.isKYC ? 'flex' : 'block'};
    padding: 20px 0 0;
    height: 80px;
    text-align: left;
    align-items: flex-start;
  }

  .menu {
    height: 40px;
    @media (min-width: ${CSS_VARS.min_desktop_screen}) {
      display: none;
    }
    > button {
      > svg {
        width: 27px;
        height: 28px;
        fill: ${CSS_VARS.color_white};
      }
    }
    > .SideNav {
      position: fixed;
      left: 0;
      top: 70px;
      width: 100%;
      height: 100%;
      z-index: 100;
      display: none;
      background: ${CSS_VARS.color_white};
    }

    &.active {
      > .SideNav {
        display: block;
      }
    }
  }
  .logo {
    flex: 1;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    > img {
      display: inline-block;
      width: 150px;
      height: auto;
    }

    @media (min-width: ${CSS_VARS.min_desktop_screen}) {
      text-align: left;
      > img {
        display: inline-block;
        width: 170px;
        height: auto;
      }
    }
  }

  .shortcuts {
    height: 40px;
    display: flex;
    justify-content: flex-end;
    @media (min-width: ${CSS_VARS.min_desktop_screen}) {
      flex: 1;
    }

    .shortcut {
      display: none;
      flex: 0 0 70px;
      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        svg {
          width: 30px;
          height: 30px;
          fill: ${CSS_VARS.color_white};
          transform: scale(0.9);
        }
        span {
          display: block;
          margin-top: 5px;
          color: ${CSS_VARS.color_white};
          font-size: ${CSS_VARS.size_xxs};
        }
      }
      &:last-child {
        display: block;
        > div > span {
          display: none;
        }
      }
      @media (min-width: ${CSS_VARS.min_desktop_screen}) {
        display: block;
        &:hover {
          text-decoration: none;
          font-weight: ${CSS_VARS.weight_bold};
          > div > span {
            text-decoration: none;
            font-weight: ${CSS_VARS.weight_bold};
          }
        }
        &:last-child {
          > div > span {
            display: block;
          }
        }
      }
    }
  }
`

class Header extends React.Component {
  state = {
    isMobileNavActive: false,
  }

  constructor(props) {
    super(props)

    this.goToMainContentRef = React.createRef()
  }

  componentDidMount() {
    // Apply a listener on the document to close the side nav when clicking outside
    document.addEventListener('click', this.globalCloseSideNav, false)

    this.goToMainContentRef.current.addEventListener('click', this.goToMainContent, false)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.globalCloseSideNav)
  }

  render() {
    const {
      isAuthenticated,
      isCoachAvailableForUser,
      userSponsorFlag,
      userSponsorEvents,
      isSponsorshipOfferAvailable,
      isSponsorshipEventsAvailable,
      isOfferAvailable,
      isCatFlashAvailable,
      catFlashAuthorizedIds,
      isCatGreenAvailable,
      clientNumber,
      hasMinorAccounts,
      hasProxyAccounts,
      userTanStatus,
      location: { pathname },
    } = this.props
    const canNavigate = isAuthenticated && pathname !== '/kyc'
    return (
      <MainCSS
        role='banner'
        className='app-header'
        isAuthenticated={isAuthenticated}
        isKYC={pathname === '/kyc'}
        isLogin={pathname === '/login'}
        isResetPassword={pathname === '/resetPassword'}
        isTanEnrolmentPage={pathname === '/tanEnrol' || pathname === '/lostPassword'}>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12'>
              <button ref={this.goToMainContentRef} type='button' className='goToMainContent'>
                Aller au contenu
              </button>
              <ContentCSS
                isAuthenticated={isAuthenticated}
                isKYC={pathname === '/kyc'}
                isResetPassword={pathname === '/resetPassword'}>
                {canNavigate && (
                  <nav
                    role='navigation'
                    aria-label='menu principal'
                    className={`menu ${this.state.isMobileNavActive ? 'active' : ''}`}>
                    <button
                      type='button'
                      aria-expanded={this.state.isMobileNavActive}
                      onClick={this.toggleNav}>
                      <span className='sr-only'>Menu</span>
                      <MenuIcon />
                    </button>
                    <SideNav
                      hideCoach={!isCoachAvailableForUser}
                      userSponsorFlag={userSponsorFlag}
                      userSponsorEvents={userSponsorEvents}
                      isSponsorshipOfferAvailable={isSponsorshipOfferAvailable}
                      isSponsorshipEventsAvailable={isSponsorshipEventsAvailable}
                      isOfferAvailable={isOfferAvailable}
                      hasMinorAccounts={hasMinorAccounts}
                      hasProxyAccounts={hasProxyAccounts}
                      isCatFlashAvailable={isCatFlashAvailable}
                      catFlashAuthorizedIds={catFlashAuthorizedIds}
                      isCatGreenAvailable={isCatGreenAvailable}
                      clientNumber={clientNumber}
                      userTanStatus={userTanStatus}
                    />
                  </nav>
                )}
                <Link className='logo' to={canNavigate ? '/' : '/login'}>
                  <img src={logo} alt='Aller à la page d’accueil, DISTINGO Bank mon compte' />
                </Link>
                {canNavigate && (
                  <div className='shortcuts'>
                    <Link
                      className='shortcut'
                      to='/contact'
                      title={intl`header_contact`}
                      onClick={() => {
                        window.dataLayer.push({
                          event: 'psa_header',
                          psa_header: intl`header_contact`,
                        })
                      }}>
                      <div>
                        <AgendaIcon />
                        <span>{intl`header_contact`}</span>
                      </div>
                    </Link>
                    <Link
                      className='shortcut'
                      to='/myspaceInformations'
                      title={intl`header_profil`}
                      onClick={() => {
                        window.dataLayer.push({
                          event: 'psa_header',
                          psa_header: intl`header_profil`,
                        })
                      }}>
                      <div>
                        <UserIcon />
                        <span>{intl`header_profil`}</span>
                      </div>
                    </Link>
                    <Link
                      className='shortcut'
                      to='logout'
                      title={intl`header_logout`}
                      onClick={() => {
                        window.dataLayer.push({
                          event: 'psa_header',
                          psa_header: intl`header_logout`,
                        })
                      }}>
                      <div>
                        <LogoutIcon />
                        <span>{intl`header_logout`}</span>
                      </div>
                    </Link>
                  </div>
                )}
              </ContentCSS>
            </div>
          </div>
        </div>
      </MainCSS>
    )
  }

  toggleNav = (event) => {
    event.nativeEvent.stopImmediatePropagation()

    this.setState((state) => {
      return {
        isMobileNavActive: !state.isMobileNavActive,
      }
    })
  }

  globalCloseSideNav = () => {
    if (this.state.isMobileNavActive) {
      this.setState({ isMobileNavActive: false })
    }
  }

  goToMainContent = () => {
    const mainElement = document.getElementById('main')
    const clientRect = mainElement.getBoundingClientRect()

    window.scrollTo({
      top: clientRect.top + window.scrollY,
      left: 0,
      behavior: 'auto',
    })

    mainElement.focus()
  }
}

const mapStateToProps = ({
  cache: {
    isCoachAvailableForUser,
    person,
    sponsorEvents,
    hasMinorAccounts,
    hasProxyAccounts,
    isCatFlashAvailable,
    catFlashAuthorizedIds,
    isCatGreenAvailable,
    isOfferAvailable,
    isSponsorshipOfferAvailable,
    isSponsorshipEventsAvailable,
    userTanStatus,
  },
}) => {
  const personExtId = (person || {}).personExtId || ''
  const clientNumber = getClientNumber(personExtId)
  let token = ''
  if (utils.app.get('session')) {
    const { idToken } = utils.app.get('session')
    token = idToken || ''
  }

  return {
    isAuthenticated: Boolean(token),
    isCoachAvailableForUser,
    userSponsorFlag: person?.sponsorFlag || false,
    userSponsorEvents: sponsorEvents || [],
    isSponsorshipOfferAvailable,
    isSponsorshipEventsAvailable,
    isOfferAvailable,
    isCatFlashAvailable: isCatFlashAvailable || false,
    catFlashAuthorizedIds,
    isCatGreenAvailable: isCatGreenAvailable || false,
    clientNumber,
    hasMinorAccounts,
    hasProxyAccounts,
    userTanStatus,
  }
}

export default connect(mapStateToProps)(withRouter(Header))
