import React, { Component } from 'react'
import DOMPurify from 'dompurify'
import styled, { CSS_VARS } from 'services/styled'

import { ReactComponent as QuestionIcon } from '../assets/svg/question-mark.svg'
import { ReactComponent as CrossIcon } from '../assets/svg/cross.svg'

const MainCSS = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
  will-change: transform;
  transform: translateZ(0);
  color: ${CSS_VARS.color_white};

  * {
    color: ${CSS_VARS.color_white};
  }

  &.hiddenBox {
    display: none;
  }
  &.productMessageBox {
    background-color: ${CSS_VARS.color_white};
    * {
      color: ${CSS_VARS.color_brand_primary};
    }
  }

  @media (min-width: ${CSS_VARS.min_desktop_screen}) {
    flex-direction: row;
    margin-bottom: ${props => props.addSpace ? '60px' : '36px'};
  }
`
const IconBlock = styled.div`
  display: none;
  position: relative;
  width: 40px;
  height: 40px;
  margin: 0 0 10px;
  background-color: ${CSS_VARS.color_brand_primary};
  border-radius: 3px;
  text-align: center;
  line-height: 29px;
  vertical-align: middle;
  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -10px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-top: 14px solid ${CSS_VARS.color_brand_primary};
  }
  svg {
    margin-top: 10px;
    width: 20px;
    height: 20px;
  }
  &.productMessageBox {
    display: none;
  }
  @media (min-width: ${CSS_VARS.min_desktop_screen}) {
    display: block;
    margin: 0 10px 0 0;
  }
`
const MessageBlock = styled.div`
  position: relative;
  flex: 1;
  padding: 12px 0;
  background-color: ${CSS_VARS.color_brand_primary};
  border-radius: 5px;
  color: ${CSS_VARS.color_white};

  a,
  a:visited,
  a:hover {
    color: ${CSS_VARS.color_brand_secondary};
  }

  > div {
    // max-height: 76px; // 100px - 12px -12px
    // overflow-y: scroll;
    padding: 0 12px;
    > p:first-child {
      margin-top: 0;
    }
  }

  .closeMessageBox {
    position: absolute;
    right: 10px;
    top: 10px;

    > svg {
      stroke: ${CSS_VARS.color_white};
      width: 10px;
      height: 10px;
    }

    @media (min-width: ${CSS_VARS.min_desktop_screen}) {
      display: none;
    }
  }

  &.productMessageBox {
    background-color: transparent;
    color: ${CSS_VARS.color_brand_primary};

    .closeMessageBox {
      > svg {
        stroke: ${CSS_VARS.color_brand_primary};
      }

      @media (min-width: ${CSS_VARS.min_desktop_screen}) {
        display: none;
      }
    }
  }
`
class MessageBox extends Component {
  state = {
    isVisible: true,
    cleanHTML: ''
  }

  componentDidMount() {
    this.setState({
      cleanHTML: DOMPurify.sanitize(this.props.htmlContent)
    })
  }

  onClose = () => {
    this.setState({
      isVisible: false
    })
  }

  render() {
    const { isProduct, noHeaderPage } = this.props
    const { isVisible } = this.state
    return (
      <MainCSS
        noHeaderPage={noHeaderPage}
        addSpace={this.props.addSpace || false}
        className={`MessageBox ${isVisible ? '' : 'hiddenBox'} ${
          isProduct ? 'productMessageBox' : ''
        }`}
      >
        {
          noHeaderPage &&
          <IconBlock className={`${isProduct ? 'productMessageBox' : ''}`}>
            <QuestionIcon />
          </IconBlock>
        }
        <MessageBlock className={`${isProduct ? 'productMessageBox' : ''}`}>
          <button type="button" className="closeMessageBox" onClick={this.onClose}>
            <CrossIcon />
          </button>
          <div dangerouslySetInnerHTML={{ __html: this.state.cleanHTML }} />
        </MessageBlock>
      </MainCSS>
    )
  }
}

export default MessageBox
